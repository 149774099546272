import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Container,
  Paper,
  Tabs,
  Tab,
  Box,
  Grid,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import { PhoneAndroid, SimCard, Payment, LiveTv } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import MobilePlan from "./mobilePalan/MobilePlan";
import { billPayRecharge, mobilePlanDetails, rechargeOperators } from "../../../../../api/login/Login";
import 'react-toastify/dist/ReactToastify.css';

import MobileRechargeList from "./mobileRechargeList/MobileRechargeList";
import Loader from "../../../../../common/loader/Loader";
import TipinModal from "../../../../../common/tipinModal/TipinModal";
import InsufficientBalanceModal from "../../../../../common/tipinModal/InsufficientBalanceModal";
import { motion } from 'framer-motion';


const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderRadius: '20px',
    boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
    background: 'linear-gradient(145deg, #f3f4f6 0%, #fff 100%)',
  }));
  
  const AnimatedButton = styled(motion.div)({
    display: 'inline-block',
    width: '100%',
  });
  
  const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 'bold',
    '&.Mui-selected': {
      color: '#1A237E',
    },
  }));
  
//   const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     fontWeight: 'bold',
//   }));

function MobileRecharge({ walletShowHeader }) {
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [insufficientBalanceModal, setInsufficientBalanceModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showBank, setShowBank] = useState(null);
  const [allOperatorData, setAllOperatorData] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const [initialData, setInitialData] = useState({
    billNumber: '',
    amount: ''
  });

  const onChange = (e, str) => {
    const { value, name } = e.target;
    setInitialData(prev => ({
      ...prev,
      [name]: value
    }));

    if (str === 'billNumber' && value.length === 10) {
      planOperator(value);
    } else if (str === 'billNumber' && value.length < 10) {
      setAllOperatorData(null);
      setShowBank(null);
      setInitialData(prev => ({ ...prev, amount: '' }));
    }
  };

  const bankChange = (event, newValue) => {
    setShowBank(newValue);
  };

  const rechargeOperator = async () => {
    setLoader(true);
    try {
      const res = await rechargeOperators();
      const mappedOperators = res?.data?.data?.operators?.map((item) => ({
        ...item,
        label: item.name,
      }));
      setBankData(mappedOperators);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const planOperator = async (num) => {
    setLoading(true);
    try {
      const res = await mobilePlanDetails(num);
      setAllOperatorData(res?.data?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const selectAmount = (item) => {
    setInitialData(prev => ({ ...prev, amount: item?.rs }));
  };

  const toastMessage = (message, type) => {
    toast[type](message, {
      position: "top-center",
      autoClose: 2000,
    });
  };

  const submitData = async (tipin) => {
    setIsSubmitting(true);
    const data = {
      ...initialData,
      tpin: tipin,
      user_id: window.localStorage.getItem('userIdToken'),
      rechargeId: showBank?._id,
    };
    try {
      const res = await billPayRecharge(data);
      if (res.data.error === false) {
        toastMessage(res.data?.message, 'success');
        setShowBank(null);
        setInitialData({ billNumber: '', amount: '' });
        if (walletShowHeader) walletShowHeader();
      } else {
        toastMessage(res.data?.message, 'error');
        if (res.data?.message.toLowerCase().includes('insufficient balance')) {
          setErrorMessage(res.data?.message);
          setInsufficientBalanceModal(true);
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setInsufficientBalanceModal(true);
    } finally {
      setIsSubmitting(false);
      setModalShow(false);
    }
  };

  useEffect(() => {
    rechargeOperator();
  }, []);

  useEffect(() => {
    if (allOperatorData && bankData.length > 0) {
      const defaultOperator = bankData.find((op) => op._id === allOperatorData.operator_code);
      setShowBank(defaultOperator);
    }
  }, [allOperatorData, bankData]);

  return (
    <>
      {(loading || loader) && <Loader />}
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          align="center"
          sx={{
            mt: 6,
            mb: 4,
            fontWeight: 'bold',
            color: '#1a237e',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
         <PhoneAndroid  sx={{ fontSize: '2rem', mr: 2 }} />
          <span>Mobile Recharge</span>
        </Typography>

        <StyledPaper elevation={3}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            centered
            sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
          >
            
            <StyledTab label="Rechage" />
            <StyledTab label="Recent Transactions" />
          </Tabs>

          <Box sx={{ p: 3 }}>
            {tabValue === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={6} >
                  <TextField
                    fullWidth
                    label="Mobile Number"
                    variant="outlined"
                    name="billNumber"
                    value={initialData.billNumber}
                    onChange={(e) => {
                      if (e.target.value.length <= 11) {
                        onChange(e, 'billNumber');
                      }
                    }}
                    margin="normal"
                    type="number"
                    InputProps={{
                      startAdornment: <PhoneAndroid />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    options={bankData}
                    getOptionLabel={(option) => option.label}
                    value={showBank}
                    onChange={bankChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Operator"
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <SimCard />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  {allOperatorData?.operator && (
                    <Typography variant="body2" align="center">
                      {allOperatorData.operator.Circle}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Amount"
                    variant="outlined"
                    name="amount"
                    value={initialData.amount}
                    onChange={(e) => {
                      // Restrict input to 10 digits
                      if (e.target.value.length <= 10) {
                        onChange(e);
                      }
                    }}
                    margin="normal"
                    type="number"
                    InputProps={{
                      startAdornment: <Payment />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'  // Center vertically if needed
                  }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  
                >
                     <AnimatedButton
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!initialData.amount || !initialData.billNumber || !showBank || isSubmitting}
                    onClick={() => setModalShow(true)}
                    sx={{ mt: 2, py: 1.5, borderRadius: '12px' }}
                    startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <Payment />}
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Proceed To Pay'}
                  </Button>
                  </AnimatedButton>
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                <MobilePlan allOperatorDat={allOperatorData} selectAmount={selectAmount} />
                </Grid>
              </Grid>
            )}

            {tabValue === 1 && (
            <MobileRechargeList/>
            )}
          </Box>
        </StyledPaper>

        <MobilePlan allOperatorData={allOperatorData} selectAmount={selectAmount} />
      </Container>

      <TipinModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        submitata={submitData}
        isSubmitting={isSubmitting}
        initialData={initialData}
      />

      <InsufficientBalanceModal
        show={insufficientBalanceModal}
        onHide={() => setInsufficientBalanceModal(false)}
        errorMessage={errorMessage}
      />

      <ToastContainer />
    </>
  );
}

export default MobileRecharge;