import React, { useState } from 'react';
import { Tabs, Tab, Card, CardContent, Typography, Box, Container, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { LocalOffer, Receipt } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';
import ServiceGrid from './ServiceGrid';
import Todaybusiness from './Todaybusiness';
import "./Tabstyle.css"
import Dashboardcard from './Dashboardcard';
// Custom theme values
const customTheme = {
  colors: {
    main: '#3498db',      // A vibrant blue
    secondary: '#e74c3c', // A complementary red
    light: '#ecf0f1',     // A light gray
    text: '#2c3e50',      // Dark blue-gray for text
    divider: '#bdc3c7',   // Light gray for dividers
    background: '#ffffff' // White background
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700
  },
  fontSize: {
    small: '14px',
    medium: '16px',
    large: '20px'
  },
  spacing: (factor) => `${8 * factor}px`,
  breakpoints: {
    sm: '600px'
  },
  shadows: {
    medium: '0 4px 6px rgba(0, 0, 0, 0.1)',
    large: '0 10px 15px rgba(0, 0, 0, 0.1)'
  }
};

const StyledTabs = styled(Tabs)({
  borderBottom: `2px solid ${customTheme.colors.divider}`,
  '& .MuiTabs-indicator': {
    backgroundColor: customTheme.colors.secondary,
    height: 3,
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: customTheme.fontWeights.medium,
  fontSize: customTheme.fontSize.medium,
  marginRight: customTheme.spacing(4),
  color: customTheme.colors.text,
  '&.Mui-selected': {
    color: customTheme.colors.main,
  },
  '&:hover': {
    color: customTheme.colors.light,
    opacity: 1,
  },
  [`@media (max-width: ${customTheme.breakpoints.sm})`]: {
    fontSize: customTheme.fontSize.small,
    marginRight: customTheme.spacing(2),
  },
});

const TabPanel = styled(Box)({
  padding: customTheme.spacing(3),
  [`@media (max-width: ${customTheme.breakpoints.sm})`]: {
    padding: customTheme.spacing(2),
  },
});

const ServiceCard = styled(Card)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s ease-in-out',
  cursor: 'pointer',
  backgroundColor: customTheme.colors.background,
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: customTheme.shadows.large,
    backgroundColor: customTheme.colors.light,
    '& .MuiTypography-root': {
      color: customTheme.colors.main,
    },
    '& .MuiSvgIcon-root': {
      color: customTheme.colors.secondary,
    },
  },
});

const CustomIcon = styled(Box)({
  fontSize: '48px',
  color: customTheme.colors.main,
  marginBottom: customTheme.spacing(2),
  transition: 'all 0.3s ease-in-out',
});

const RetailerDashboard = ({ storedUserType, services, data, isInstantpaypresnt}) => {
  const [tabValue, setTabValue] = useState(0);
  const isMobile = useMediaQuery(`(max-width: ${customTheme.breakpoints.sm})`);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (storedUserType !== 'Retailer') {
    return (
      <Box sx={{ p: customTheme.spacing(3) }}>
        {/* <Typography variant="h4" sx={{ color: customTheme.colors.text, fontWeight: customTheme.fontWeights.bold }}>
          Transactions
        </Typography> */}
      <Dashboardcard data={data}/>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <StyledTabs 
        value={tabValue} 
        onChange={handleTabChange} 
        aria-label="dashboard tabs"
        variant={isMobile ? "fullWidth" : "standard"}
        centered={!isMobile}
      >
        <StyledTab icon={<LocalOffer />} label="Services" />
        <StyledTab icon={<Receipt />} label="Transactions" />
      </StyledTabs>
      <TabPanel>
        {tabValue === 0 && <ServiceGrid services={services}  isInstantpaypresnt={isInstantpaypresnt}/>}
        {tabValue === 1 && (
          <Box>
            {/* <Typography variant="h4" gutterBottom sx={{ color: customTheme.colors.main, fontWeight: customTheme.fontWeights.bold }}>
              Transaction Page
            </Typography> */}
            <Todaybusiness data={data} />
          </Box>
        )}
      </TabPanel>
    </Container>
  );
};

// const ServiceGrid = ({ services }) => {
//   return (
//     <Row xs={1} sm={2} md={3} lg={4} className="g-4">
//       {services.map((service, index) => (
//         <Col key={index}>
//           <ServiceCard>
//             <CardContent>
//               <CustomIcon component={service.icon} />
//               <Typography variant="h6" component="div" sx={{ color: customTheme.colors.text, fontWeight: customTheme.fontWeights.medium }}>
//                 {service.name}
//               </Typography>
//             </CardContent>
//           </ServiceCard>
//         </Col>
//       ))}
//     </Row>
//   );
// };

export default RetailerDashboard;