

// export const baseUrl = 'https://devapi.paypandabnk.com/api/'
// export const baseUrl = 'http://localhost:3200/api/'
// new url
// export const baseUrl = 'https://api.paypanda.in/api/'

// export const baseUrlImage = 'https://devapi.paypandabnk.com/cloudinary/'



export const baseUrl = 'https://devapi.paypanda.in/api/'


export const baseUrlImage = 'https://api.paypanda.in/api/cloudinary/'
