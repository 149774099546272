import React, { useState, useEffect } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaEye, FaUsers } from 'react-icons/fa';
import { Pagination } from 'antd';

const SubUserTablenew = ({ 
  users,
  totalCount,
  onUserClick,
  onTransferClick,
  onReverseTransferClick,
  onReportClick,
  onPageChange,
  handleReportClickmainreportasmzsm,
  level = 0,
  initialPage = 1,
  initialPageSize = 10
}) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [subUserPages, setSubUserPages] = useState({});

  useEffect(() => {
    setCurrentPage(initialPage);
    setPageSize(initialPageSize);
  }, [initialPage, initialPageSize]);

  const handlePageChange = async (page, size) => {
    setCurrentPage(page);
    if (size !== pageSize) {
      setPageSize(size);
    }
    // Call the parent's onPageChange with the updated page and size
    await onPageChange(page, size);
  };

  const handleSubUserPageChange = async (userId, page, size) => {
    setSubUserPages(prev => ({
      ...prev,
      [userId]: { page, size }
    }));
    // This will be handled by the SubUserRow component
  };

  if (!users?.length) {
    return (
      <div className="text-center py-4">
        <FaUsers size={24} className="mb-2" />
        <p>No downstream members available</p>
      </div>
    );
  }

  return (
    <div className="border rounded my-2">
      <div className="bg-light p-2 d-flex justify-content-between align-items-center border-bottom">
        <h6 className="mb-0">Downstream Members (Level {level + 1})</h6>
        {/* <Form.Select 
          style={{ width: '150px' }}
          value={pageSize}
          onChange={e => handlePageChange(1, Number(e.target.value))}
        >
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
        </Form.Select> */}
      </div>

      <Table hover className="mb-0">
        <thead className="bg-light">
          <tr>
            <th>S.No</th>
            <th>Refer ID</th>
            <th>Name</th>
            <th>User Type</th>
            <th>Mobile no</th>
            <th>Email Id</th>
            <th>Status</th>
            <th>Downstream</th>
            <th>Actions</th>
            <th>Wallet</th>
            <th>Report</th>
            <th>Wallet Report</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <SubUserRow 
              key={user._id}
              user={user}
              index={index + ((currentPage - 1) * pageSize)}
              onUserClick={onUserClick}
              onTransferClick={onTransferClick}
              onReverseTransferClick={onReverseTransferClick}
              onReportClick={onReportClick}
              level={level}
              currentPage={subUserPages[user._id]?.page || 1}
              pageSize={subUserPages[user._id]?.size || 10}
              onPageChange={(page, size) => handleSubUserPageChange(user._id, page, size)}
              handleReportClickmainreportasmzsm={handleReportClickmainreportasmzsm}
            />
          ))}
        </tbody>
      </Table>

      {totalCount > pageSize && (
        <div className="p-3 border-top">
          <Pagination
            current={currentPage}
            total={totalCount}
            pageSize={pageSize}
            onChange={(page) => handlePageChange(page, pageSize)}
            showSizeChanger={true}
            onShowSizeChange={(current, size) => handlePageChange(1, size)}
            showQuickJumper={true}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            className="justify-content-end"
          />
        </div>
      )}
    </div>
  );
};

const SubUserRow = ({
  user,
  index,
  onUserClick,
  onTransferClick,
  onReverseTransferClick,
  onReportClick,
  level,
  currentPage,
  pageSize,
  onPageChange,
  handleReportClickmainreportasmzsm
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [subUsers, setSubUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subUserTotalCount, setSubUserTotalCount] = useState(0);

  const fetchSubUsers = async (page, size) => {
    setLoading(true);
    try {
      const result = await onUserClick(user._id, page, size);
      if (result?.data) {
        setSubUsers(result.data);
        setSubUserTotalCount(result.totalCount);
      }
    } catch (error) {
      console.error('Error fetching sub-users:', error);
    }
    setLoading(false);
  };

  const handleExpand = async () => {
    if (!isExpanded && !subUsers.length) {
      await fetchSubUsers(currentPage, pageSize);
    }
    setIsExpanded(!isExpanded);
  };

  const handleSubUserPageChange = async (page, size) => {
    await fetchSubUsers(page, size);
    onPageChange(page, size);
  };

  return (
    <React.Fragment>
      <tr>
        <td>{index + 1}</td>
        <td>{user?.refer_id}</td>
        <td>{user?.name}</td>
        <td>
          <span className="badge bg-primary">{user?.user_type}</span>
        </td>
        <td>{user?.mobile}</td>
        <td>{user?.email}</td>
        <td>
          <span className={`badge ${user.is_approved ? 'bg-success' : 'bg-warning'}`}>
            {user?.is_approved ? 'Approved' : 'Pending'}
          </span>
        </td>
        <td>
        {user.user_type !== "Retailer" && (
    <Button
      variant="link"
      onClick={handleExpand}
      disabled={loading}
      className="d-flex align-items-center"
    >
      {loading ? 'Loading...' : 'View'}
      {isExpanded ? <FaChevronUp className="ms-1" /> : <FaChevronDown className="ms-1" />}
    </Button>
  )}
        </td>
        <td>
          <div className="d-flex gap-2">
            <Button 
              variant="outline-primary" 
              size="sm"
              onClick={() => onTransferClick(user)}
            >
              Transfer
            </Button>
            <Button 
              variant="outline-secondary" 
              size="sm"
              onClick={() => onReverseTransferClick(user)}
            >
              Reverse
            </Button>
          </div>
        </td>
        <td>₹{user?.main_wallet || 0}</td>
        <td>
          <Button variant="link" onClick={() => onReportClick(user._id)}>
            <FaEye />
          </Button>
        </td>
        <td>
        <Button variant="warning" onClick={() => handleReportClickmainreportasmzsm(user._id)}>
            Wallet Report
          </Button>
        </td>
      </tr>
      
      {isExpanded && (
        <tr>
          <td colSpan="11" className="p-0">
            <div 
              className="ps-4 border-start border-primary" 
              style={{ marginLeft: `${level * 20}px` }}
            >
              {loading ? (
                <div className="text-center py-3">Loading...</div>
              ) : subUsers.length > 0 ? (
                <SubUserTablenew
                  users={subUsers}
                  totalCount={subUserTotalCount}
                  onUserClick={onUserClick}
                  onTransferClick={onTransferClick}
                  onReverseTransferClick={onReverseTransferClick}
                  onReportClick={onReportClick}
                  onPageChange={handleSubUserPageChange}
                  level={level + 1}
                  initialPage={currentPage}
                  initialPageSize={pageSize}
                  handleReportClickmainreportasmzsm={handleReportClickmainreportasmzsm}
                />
              ) : (
                <div className="text-center py-3">No downstream members available</div>
              )}
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export { SubUserTablenew, SubUserRow };