import { Steps } from "antd";
import MerchantLoginAreaBanner from "../../../common/merchantLoginAreaBanner/MerchantLoginAreaBanner";
import MerchantLoginHeader from "../../../common/merchantLoginHeader/MerchantLoginHeader";
import { TopSection } from "../../../components/compeleteRegister/TopSection";
import { useEffect, useState } from "react";
import { UploadDoc } from "../../../components/compeleteRegister/UploadDoc";
import { VedioKyc } from "../../../components/compeleteRegister/VedioKyc";
import { FirstForm } from "../../../components/compeleteRegister/FirstForm";
import { SecForm } from "../../../components/compeleteRegister/SecForm";
import { GstBusinessForm } from "../../../components/compeleteRegister/GstBusinessForm";
import { BankDetail } from "../../../components/compeleteRegister/BankDetail";
import { userValidate, userValidateAllData } from "../../../api/login/Login";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../components/compeleteRegister/kyc.css";

function CompeleteRegister() {
  const [submitVerified, setSubmitVerified] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [state, setState] = useState(0);
  const [datas, setDatas] = useState();
  const [allData, setAllData] = useState(null);
  const [backBtnFun, setBackBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState('/');

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location.pathname]);

  const items = [
    { title: "Identity Verification" },
    { title: "Personal Details" },
    { title: "Video KYC" },
  ];

  const getVarifyall = async (num) => {
    try {
      setIsLoading(true);
      const res = await userValidate();
      setDatas(res.data);
      window.localStorage.setItem("regisNumber", res.data?.mobile);

      // Check if user is ASM or ZSM
      const isAsmOrZsm = res.data?.user_type_id?.user_type === "asm" || res.data?.user_type_id?.user_type === "zsm";

      if (isAsmOrZsm) {
        // For ASM/ZSM, limit to steps 0, 1, and 5 (Video KYC)
        if (num > 1 && num < 5) {
          setState(5); // Skip to Video KYC
        } else {
          setState(num);
        }
      } else {
        setState(num);
      }

      if (res.data?.is_document) {
        if (state === 5) {
          setState(5);
          setModalShow(true);
        }
      }
      if (res.data?.is_self_declare === true && res.data?.is_approved === false) {
        navigate("/is_self_declare");
      }
      if (res.data?.is_approved === true) {
        navigate("/Dashboard");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getVarifyallData = async () => {
    try {
      const res = await userValidateAllData();
      setAllData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const completeSteps = window.localStorage.getItem("steps");
    completeSteps === "null" ? getVarifyall(0) : getVarifyall(+completeSteps);
    getVarifyallData();
  }, [state]);

  const backButton = () => {
    setState((prevState) => Math.max(prevState - 1, 0));
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    const isAsmOrZsm = datas?.user_type_id?.user_type === "asm" || datas?.user_type_id?.user_type === "zsm";

    switch (state) {
      case 0:
        return (
          <FirstForm
            datas={datas}
            setState={setState}
            state={state}
            getVarifyall={() => getVarifyall(state)}
            adhaar_number={allData?.adhaar_number}
            allData={allData}
            backButton={backButton}
          />
        );
      case 1:
        return (
          <SecForm
            setState={setState}
            stepCount={state}
            persnalData={allData?.alternate_mobileNo}
            allData={allData}
            getVarifyallData={getVarifyallData}
            submitVerified={submitVerified}
            setSubmitVerified={setSubmitVerified}
            backButton={backButton}
          />
        );
      case 2:
        return !isAsmOrZsm ? (
          <GstBusinessForm
            setState={setState}
            state={state}
            gstData={{
              ...allData?.gst,
              isGstAvailable: allData?.isGstAvailable,
              shop_name: allData?.shop_name,
            }}
            getVarifyallData={getVarifyallData}
            allData={allData}
            submitVerified={submitVerified}
            setSubmitVerified={setSubmitVerified}
            backButton={backButton}
          />
        ) : null;
      case 3:
        return !isAsmOrZsm ? (
          <BankDetail
            setState={setState}
            state={state}
            bankDetail={allData?.bank}
            getVarifyallData={getVarifyallData}
            submitVerified={submitVerified}
            setSubmitVerified={setSubmitVerified}
            backButton={backButton}
          />
        ) : null;
      case 4:
        return !isAsmOrZsm ? (
          <UploadDoc
            setState={setState}
            docs={allData?.docs}
            modalShow={modalShow}
            setModalShow={setModalShow}
            backButton={backButton}
            backBtnFun={backBtnFun}
            setBackBtn={setBackBtn}
          />
        ) : null;
      case 5:
        return (
          <VedioKyc
            datas={datas}
            setState={setState}
            modalShow={modalShow}
            setModalShow={setModalShow}
            backButton={backButton}
            setBackBtn={setBackBtn}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <MerchantLoginHeader currentPage={currentPage} />
      <MerchantLoginAreaBanner title="Merchant Create account" />
      <section className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <TopSection />
              <Steps 
                current={state} 
                labelPlacement="vertical" 
                items={datas?.user_type_id?.user_type === "asm" || datas?.user_type_id?.user_type === "zsm" 
                  ? items 
                  : [
                      { title: "Identity Verification" },
                      { title: "Personal Details" },
                      { title: "GST & Business Details" },
                      { title: "Bank Details" },
                      { title: "Upload Document" },
                      { title: "Video KYC" },
                    ]
                } 
              />
              <div className="firstFor">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CompeleteRegister;