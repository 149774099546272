import PanService from "../../../components/admin/panService/PanServices"
import Comingsoonpan from "../../../components/admin/quickDhann/Comingsoonpan"


function PanServicesPage() {
    return (
        <>
            {/* <PanService /> */}
            {<Comingsoonpan />}
        </>
    )
}
export default PanServicesPage