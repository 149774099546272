import React, { useState, useEffect, useCallback ,useRef } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Tab,
  Tabs,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Pagination, InputAdornment
} from '@mui/material';
import { styled } from '@mui/system';
import { Autocomplete } from '@mui/material';
import { motion } from 'framer-motion';
import {
  Bolt,
  CreditCard,
  DirectionsCar,
  Phone,
  LocalGasStation,
  Tv,
  Router,
  WaterDrop,
  PhoneAndroid,
  School,
  Kitchen,
  SubwayOutlined,
  AccountBalance,
  LocalHospital,
  Home,
  Business,
  SportsKabaddi,
  Subscriptions,
  House,
  AccountBalanceWallet,
  Favorite, Visibility,
  Search,
  Clear
} from '@mui/icons-material';
import { FaMobileAlt, FaLightbulb, FaShippingFast, FaRupeeSign, FaGraduationCap, FaHospital, FaTools } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { CgDisplayFullwidth } from "react-icons/cg";
import { BsFillFileEarmarkPostFill } from "react-icons/bs";
import { GiGasStove } from "react-icons/gi";
import { IoIosWater } from "react-icons/io";
import { MdOutlineDeveloperBoard } from "react-icons/md";
import { CiCreditCard1 } from "react-icons/ci";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { AiFillInsurance } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  bbpsCategory,
  billPay,
  billPayment,
  operatorApi,
  bbps_txnElectri,
  getBbpsInquiry,
  getServiceCategoryid,
  UserPermissionAeps2
} from "../../../../../api/login/Login";
import { useNavigate } from "react-router-dom";
import TipinModal from '../../../../../common/tipinModal/TipinModal';
import Creditcardtpinmodal from '../../../../../common/tipinModal/Creditcardtpinmodal';
import AepsDipositPurchge from '../../../../../pages/admin/cashDiposit/aepsDipositPurchage/AepsDipositPurchage';



const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  borderRadius: '20px',
  boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
  background: 'linear-gradient(145deg, #f3f4f6 0%, #fff 100%)',
}));

const AnimatedButton = styled(motion.div)({
  display: 'inline-block',
  width: '100%',
});

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  '&.Mui-selected': {
    color: '#1A237E',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

const BillPayment = ({ walletShowHeader, billType }) => {
  const [tabValue, setTabValue] = useState(0);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedFilterOperator, setSelectedFilterOperator] = useState(null);
  const [billDetails, setBillDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [payloadSend, setPayloadSend] = useState();
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const navigate = useNavigate();
  const [editableAmount, setEditableAmount] = useState("");
  const [isAmountEdited, setIsAmountEdited] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [hasPermission, setHasPermission] = useState(false);
  const [permissionMessage, setPermissionMessage] = useState('');

  const [filterInitial, setFilterInitial] = useState({
    txn_id: '',
    start_date: new Date().toISOString().substr(0, 10),
    end_date: new Date().toISOString().substr(0, 10),
    biller_id: '',
    page: 0,
  });

  const [formData, setFormData] = useState({
    billerId: "",
    customParams: [
      {
        name: "",
        value: ''
      }
    ],
    amount: '',
    refId: '',
    billerResponse: '',
    user_id: window.localStorage.getItem('userIdToken')
  });
  const resetFormState = useCallback(() => {
    setFormData({
      billerId: "",
      customParams: [
        {
          name: "",
          value: ''
        }
      ],
      amount: '',
      refId: '',
      billerResponse: '',
      user_id: window.localStorage.getItem('userIdToken')
    });
    setSelectedOperator(null);
    setBillDetails(null);
    setPayloadSend(null);
    setTabValue(0);
    setTransactions([]);
    setFilterInitial({
      txn_id: '',
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      biller_id: '',
      page: 0,
    });
    setSelectedFilterOperator(null);
  }, []);
  const [billTypeInfo, setBillTypeInfo] = useState({
    icon: <Bolt />,
    title: 'Bill Payment',
    categoryId: '233'
  });
  const staticCategoryIds = {
    'Electricity': { id: '667e8a8aa0cc9372aaceb006', icon: <Bolt />, permissionId: '18' },
    'Credit Card': { id: '667e8a8aa0cc9372aaceb009', icon: <CreditCard />, permissionId: '19' },
    'Fastag': { id: '667e8a8aa0cc9372aaceb004', icon: <FaShippingFast />, permissionId: '9' },
    'Landline Postpaid': { id: '667e8a8aa0cc9372aaceb007', icon: <Phone />, permissionId: '12' },
    'Gas': { id: '667e8a8aa0cc9372aaceb000', icon: <LocalGasStation />, permissionId: '11' },
    'Broadband Postpaid': { id: '667e8a8aa0cc9372aaceafff', icon: <Router />, permissionId: '5' },
    'Water ': { id: '667e8a8aa0cc9372aaceb00e', icon: <WaterDrop />, permissionId: '28' },
    'Mobile Postpaid': { id: '667e8a8aa0cc9372aaceb008', icon: <PhoneAndroid />, permissionId: '10' },
    'Education Fees': { id: '667e8a8aa0cc9372aaceaffb', icon: <School />, permissionId: '3' },
    'LPG Gas Bill Payment': { id: '667e8a8aa0cc9372aaceb00a ', icon: <GiGasStove />, permissionId: '16' },
    'NCMC Recharge': { id: '667e8a8aa0cc9372aaceb015', icon: <SubwayOutlined />, permissionId: '31' },
    'Municipal Taxes': { id: '667e8a8aa0cc9372aaceaffd', icon: <AccountBalance />, permissionId: '7' },
    'Quick Dhan ': { id: '6688d78e48e025dfc248c68d', icon: <AccountBalanceWallet />, permissionId: '36' },
    'Insurance': { id: '667e8a8aa0cc9372aaceb001', icon: <Favorite />, permissionId: '8' },
    'Municipal Services': { id: '667e8a8aa0cc9372aaceb00f', icon: <Business />, permissionId: '17' },
    'Metro Recharge': { id: '667e8a8aa0cc9372aaceb016', icon: <SubwayOutlined />, permissionId: '29' },
    'Loan Repayment': { id: '667e8a8aa0cc9372aaceaffa', icon: <AccountBalance />, permissionId: '2' },
    'Life Insurance': { id: '667e8a8aa0cc9372aaceb011', icon: <Favorite />, permissionId: '22' },
    'Housing Society': { id: '667e8a8aa0cc9372aaceaffc', icon: <Home />, permissionId: '4' },
    'Hospital and Pathology': { id: '667e8a8aa0cc9372aaceb00b', icon: <LocalHospital />, permissionId: '15' },
    'Hospital': { id: '667e8a8aa0cc9372aaceb00c', icon: <LocalHospital />, permissionId: '14' },
    'Health Insurance': { id: '667e8a8aa0cc9372aaceb012', icon: <Favorite />, permissionId: '26' },
    'Clubs and Associations': { id: '667e8a8aa0cc9372aaceaffe', icon: <SportsKabaddi />, permissionId: '30' },
    'Cable TV': { id: '667e8a8aa0cc9372aaceb00', icon: <Tv />, permissionId: '21' },
    'B2B': { id: '667e8a8aa0cc9372aaceb014', icon: <Business />, permissionId: '24' },
    'Subscription': { id: '667e8a8aa0cc9372aaceb003', icon: <Subscriptions />, permissionId: '6' },
    'Rental': { id: '667e8a8aa0cc9372aaceb010', icon: <House />, permissionId: '25 ' },
    'Recurring Deposit': { id: '667e8a8aa0cc9372aaceb013', icon: <AccountBalanceWallet />, permissionId: '216' },
    'Donation': { id: '667e8a8aa0cc9372aaceb017', icon: <Favorite />, permissionId: '27' },
  };


  const checkPermission = useCallback(async (permissionId) => {
    try {
      const res = await UserPermissionAeps2(permissionId);
      if (res?.data?.data?.is_active === false) {
        setPermissionMessage('Please Conatct To Admin to Active this Service');
        setHasPermission(false);
      } else if (res?.data?.data?.is_active === true && res?.data?.data?.is_buy === false) {
        setPermissionMessage('Please Purchase this Service');
        setHasPermission(false);
      } else if (res?.data?.data?.is_active === true && res?.data?.data?.is_buy === true) {
        setHasPermission(true);
      }
    } catch (error) {
      console.error("Error checking permission:", error);
      setPermissionMessage('Error checking permission');
      setHasPermission(false);
    }
  }, []);

  useEffect(() => {
    const fetchCategoryId = async () => {
      try {
        let categoryId;
        let icon;
        let permissionId;

        if (staticCategoryIds[billType]) {
          categoryId = staticCategoryIds[billType].id;
          icon = staticCategoryIds[billType].icon;
          permissionId = staticCategoryIds[billType].permissionId;
        } else {
          const res = await getServiceCategoryid();
          if (res.data && res.data.error === false && res.data.data && res.data.data.length > 0) {
            const matchingService = res.data.data.find(service =>
              service.service_name.toLowerCase() === billType.toLowerCase()
            );

            if (matchingService) {
              categoryId = matchingService._id;
              permissionId = matchingService.permissionId;
            } else {
              throw new Error('Matching service not found');
            }
          } else {
            throw new Error('Invalid response from getServiceCategoryid');
          }
        }

        setBillTypeInfo({
          icon: icon || <Bolt />,
          title: `${billType} Bill Payment`,
          categoryId: categoryId
        });

        if (permissionId) {
          await checkPermission(permissionId);
        } else {
          setHasPermission(true); // Assume permission if no permissionId is set
        }
      } catch (error) {
        console.error("Error fetching category ID:", error);
        toast.error("Failed to fetch category ID: " + error.message);
      }
    };

    fetchCategoryId();
  }, [billType, checkPermission]);


  const fetchTransactions = useCallback(async (pageNum, filter = filterInitial) => {
    setLoading(true);
    const params = {
      ...filter,
      count: count,
      category: billTypeInfo.categoryId,

      biller_id: selectedFilterOperator ? selectedFilterOperator?._id : '',
      page: pageNum,
      user_id: window.localStorage.getItem('userIdToken')
    };
    try {
      const res = await bbps_txnElectri(params);
      if (res?.data?.error === false) {
        setTotalCount(res?.data?.totalCount);
        setTotalAmount(res?.data?.totalAmount);
        setTransactions(res?.data?.data);
      } else {
        // toast.error(res?.data?.message || "Failed to fetch transactions");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.error("Failed to fetch transactions");
    }
    setLoading(false);
  }, [filterInitial, count, billTypeInfo.categoryId]);

  const fetchOperators = useCallback(async () => {
    if (!billTypeInfo.categoryId) return;

    try {
      const res = await operatorApi(billTypeInfo.categoryId);
      if (res?.data?.error === false) {
        const mappedOperators = res?.data?.data?.map((item) => ({
          ...item,
          label: item.name
        }));
        setOperators(mappedOperators);
      } else {
        // toast.error(res?.data?.message || "Failed to fetch operators");
      }
    } catch (error) {
      console.error("Error fetching operators:", error);
      toast.error("Failed to fetch operators");
    }
  }, [billTypeInfo.categoryId]);

  useEffect(() => {
    if (billTypeInfo.categoryId) {
      fetchOperators();
    }
  }, [billTypeInfo.categoryId, fetchOperators]);

  const handleOperatorChange = (event, newValue) => {
    if (!newValue) {
      setSelectedOperator(null);
      setFormData(prev => ({
        ...prev,
        billerId: "",
        customParams: []
      }));
      return;
    }

    setSelectedOperator(newValue);
    setFormData(prev => ({
      ...prev,
      billerId: newValue.operatorid,
      customParams: newValue.customerParams?.map(param => ({
        name: param.paramName,
        value: ''
      })) || []
    }));
  };

  const handleCustomParamChange = (index, value) => {
    setFormData(prev => {
      const newParams = [...prev.customParams];
      newParams[index].value = value;
      return { ...prev, customParams: newParams };
    });
  };

  const handleViewBill = async () => {
    setLoading(true);
    try {
      const res = await billPayment(formData);
      if (res?.data?.error === false) {
        toast.success(res?.data?.message);
        setBillDetails(res?.data?.data?.billerResponse);
        setPayloadSend(res?.data?.data);
        setEditableAmount(res?.data?.data?.billerResponse?.amount?.toString());
      } else {
        toast.error(res?.data?.message || "Something Went Wrong.");
      }
    } catch (error) {
      console.error("Error viewing bill:", error);
      toast.error("Failed to fetch bill details");
    }
    setLoading(false);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setAmountError("Amount cannot be empty");
    } else if (isNaN(value) || parseFloat(value) <= 0) {
      setAmountError("Please enter a valid amount");
    } else {
      setAmountError("");
    }
    setEditableAmount(value);
    setIsAmountEdited(true);
  };

  const paySubmit = async (tipin) => {
    if (amountError) {
      toast.error("Please enter a valid amount");
      return;
    }
    setLoader1(true);
    const amountToUse = billType === 'Credit Card' && isAmountEdited ? editableAmount : billDetails?.amount;
    const payloadData = {
      ...formData,
      tpin: tipin,
      billerResponse: payloadSend.billerResponse,
      refId: payloadSend.refId,
      amount: amountToUse
    };

    try {
      const res = await billPay(payloadData);
      if (res?.data?.error === false) {
        toast.success(res?.data?.message);
        setModalShow(false);
        if (walletShowHeader) walletShowHeader();
        resetForm();
      } else {
        toast.error(res?.data?.message || "Something Went Wrong.");
      }
    } catch (error) {
      console.error("Error paying bill:", error);
      toast.error("An error occurred while processing your payment");
    } finally {
      setLoader1(false);
    }
  };
  // const paySubmit = async (tipin) => {
  //   setLoader1(true);
  //   const payloadData = {
  //     ...formData,
  //     tpin: tipin,
  //     billerResponse: payloadSend.billerResponse,
  //     refId: payloadSend.refId,
  //     amount: billDetails?.amount
  //   };

  //   try {
  //     const res = await billPay(payloadData);
  //     if (res?.data?.error === false) {
  //       toast.success(res?.data?.message);
  //       setModalShow(false);
  //       if (walletShowHeader) walletShowHeader();
  //       resetForm();
  //     } else {
  //       toast.error(res?.data?.message || "Something Went Wrong.");
  //     }
  //   } catch (error) {
  //     console.error("Error paying bill:", error);
  //     toast.error("An error occurred while processing your payment");
  //   } finally {
  //     setLoader1(false);
  //   }
  // };

  const resetForm = () => {
    setFormData({
      billerId: "",
      customParams: [{ name: "", value: "" }],
      amount: "",
      refId: "",
      billerResponse: "",
      user_id: window.localStorage.getItem('userIdToken'),
    });
    setSelectedOperator(null);
    setBillDetails(null);
    setPayloadSend(null);
    setEditableAmount("");
    setIsAmountEdited(false)
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterInitial(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    setFilterInitial(prev => ({ ...prev, page: 0 }));
    fetchTransactions(0, filterInitial);
  };

  const handleReset = () => {
    // Reset the filter values
    const resetFilter = {
      txn_id: '',
      start_date: "",
      end_date: "",
      biller_id: '',
      page: 0,  // Reset page to 0
    };

    // Update filter state and reset transactions
    setFilterInitial(resetFilter);
    setSelectedFilterOperator(null);
    // setTransactions([]); 

    // Reset page to 0
    setPage(0);
    fetchTransactions(0, resetFilter);
  };

  // Use useEffect to trigger fetching transactions when filter or page changes
  useEffect(() => {
    // Only fetch transactions when the page changes, not when filterInitial changes
    fetchTransactions(page);
    handleReset()
  }, [page]);

  const handlePageChange = (event, value) => {

    setFilterInitial(prev => ({ ...prev, page: value - 1 }));
    fetchTransactions(value - 1);
  };
  const handleFilterOperatorChange = (event, newValue) => {
    console.log(newValue)
    setSelectedFilterOperator(newValue);
    setFilterInitial(prev => ({ ...prev, biller_id: newValue ? newValue._id : '' }));
  };

  const handleInquiry = async (txnId) => {
    setLoading2(true); // Set loading state to true
    try {
      const res = await getBbpsInquiry(txnId);
      if (res?.data?.error === false) {
        toast.success(res?.data?.message);
        fetchTransactions(0);
      } else {
        toast.error(res?.data?.message || "Something Went Wrong.");
      }
    } catch (error) {
      console.error("Error in inquiry:", error);
      toast.error("Inquiry failed");
    } finally {
      setLoading2(false); // Set loading state to false regardless of success or error
    }
  };

  const handleViewInvoice = (id) => {
    navigate(`/bill-invoice/${id}`);
  };
  useEffect(() => {
    resetFormState();
  }, [billType, resetFormState]);

  useEffect(() => {
    return () => {
      resetFormState();
    };
  }, [resetFormState]);

  if (!hasPermission) {
    return <AepsDipositPurchge activeMessage={permissionMessage} buyMessage={permissionMessage} head={`${billType} Bill Payment`} />;
  }


  return (
    <Container maxWidth="lg">

      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        align="center"
        sx={{
          mt: 6,
          mb: 4,
          fontWeight: 'bold',
          color: '#1a237e',
          display: 'flex', // Use flex to align items
          justifyContent: 'center', // Center align the items
          alignItems: 'center', // Vertically center align
        }}
      >
        <span style={{ fontSize: '2rem', marginRight: '10px' }}>{billTypeInfo.icon}</span>
        <span style={{ fontSize: '2rem' }}>{billTypeInfo.title}</span>
      </Typography>


      <StyledPaper elevation={3}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          centered
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
        >
          <StyledTab label="Pay Bill" />
          <StyledTab label="Recent Transactions" />
        </Tabs>

        <Box sx={{ p: 3 }}>
          {tabValue === 0 && (
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={operators}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Operator"
                      fullWidth
                      variant="outlined"
                      error={!selectedOperator && formData.customParams.some(param => param.value)}
                      helperText={!selectedOperator && formData.customParams.some(param => param.value) ? "Please select an operator" : ""}
                    />
                  )}
                  onChange={handleOperatorChange}
                  value={selectedOperator}
                />
              </Grid>
              {formData.customParams?.map((param, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <TextField
                    label={param.name}
                    fullWidth
                    variant="outlined"
                    value={param.value}
                    onChange={(e) => handleCustomParamChange(index, e.target.value)}
                    error={!param.value && selectedOperator}
                    helperText={!param.value && selectedOperator ? "This field is required" : ""}
                  />
                </Grid>
              ))}

              <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'  // Center vertically if needed
              }}>
                <Grid
                  item
                  xs={12}
                  sm={4}

                >
                  <AnimatedButton
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleViewBill}
                      startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Visibility />}
                      disabled={loading || !selectedOperator || formData.customParams?.some(param => !param.value)}
                      fullWidth
                      size="large"
                      sx={{ py: 1.5, borderRadius: '12px' }}
                    >
                      {loading ? 'Fetching Bill...' : 'View Bill'}
                    </Button>
                  </AnimatedButton>
                </Grid>

              </Grid>
            </Grid>
          )}

          {tabValue === 0 && billDetails && (
            <Box mt={6} p={3} sx={{ background: '#f0f4f8', borderRadius: '15px' }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#2c3e50' }}>Bill Details</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Customer Name:</strong> {billDetails.customerName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Amount:</strong> ₹{billDetails.amount}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Bill Date:</strong> {billDetails.billDate}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Due Date:</strong> {billDetails.dueDate}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {billType === 'Credit Card' ? (
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Amount"
                        fullWidth
                        variant="outlined"
                        value={editableAmount}
                        onChange={handleAmountChange}
                        error={!!amountError}
                        helperText={amountError}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  ) : (
                    <Typography variant="body1"><strong>Amount:</strong> ₹{billDetails.amount}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'  // Center vertically if needed
              }}>
                <Grid
                  item
                  xs={12}
                  sm={4}

                >
                  <Box mt={3}>
                    <AnimatedButton
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setModalShow(true)}
                        fullWidth
                        disabled={billDetails.amount === 0 || amountError}
                        size="large"
                        sx={{ py: 1.5, borderRadius: '12px' }}

                      >
                        Pay Now
                      </Button>
                    </AnimatedButton>
                  </Box>
                </Grid>
              </Grid>
              {billType === 'Credit Card' && isAmountEdited && (
                <Typography variant="body2" color="warning.main" mt={2}>
                  Warning: The amount has been modified from the original bill amount.
                </Typography>
              )}
            </Box>
          )}

          {tabValue === 1 && (
            <>
              <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Transaction ID"
                    name="txn_id"
                    value={filterInitial.txn_id}
                    onChange={handleFilterChange}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Start Date"
                    type="date"
                    name="start_date"
                    value={filterInitial.start_date}
                    onChange={handleFilterChange}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: new Date().toISOString().split('T')[0] }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="End Date"
                    type="date"
                    name="end_date"
                    value={filterInitial.end_date}
                    onChange={handleFilterChange}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: new Date().toISOString().split('T')[0] }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    options={operators}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Operator"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    onChange={handleFilterOperatorChange}
                    value={selectedFilterOperator}

                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box display="flex" justifyContent="space-between">
                    <Button startIcon={<Search />} variant="contained" onClick={handleSearch} sx={{ mr: 1, flex: 1 }}>
                      Search
                    </Button>
                    <Button startIcon={<Clear />} variant="outlined" onClick={handleReset} sx={{ flex: 1 }}>
                      Reset
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              <TableContainer component={Paper} sx={{ boxShadow: '0 4px 12px rgba(0,0,0,0.1)', borderRadius: '12px' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                      <StyledTableCell>S.No</StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Biller ID</StyledTableCell>
                      <StyledTableCell>Operator</StyledTableCell>
                      <StyledTableCell align="right">Amount</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                      <StyledTableCell>Inquiry</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.length > 0 ? (
                      transactions.map((row, index) => (
                        <TableRow key={row._id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                          <TableCell>{index + 1 + (page * count)}</TableCell>
                          <TableCell>{row.createdAt}</TableCell>
                          <TableCell>{row.biller_id}</TableCell>
                          <TableCell>{row.operator_name}</TableCell>
                          <TableCell align="right">₹{row.amount}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              size="small"
                              color={
                                row.status == 2
                                  ? 'success'
                                  : row.status == 1
                                    ? 'warning'
                                    : row.status == 3
                                      ? 'error'
                                      : row.status == 4
                                        ? 'info' // Color for Refund status
                                        : 'default' // Default color for unknown status
                              }
                              sx={{ borderRadius: '20px', textTransform: 'capitalize' }}
                            >
                              {row.status == 2
                                ? 'Success'
                                : row.status == 1
                                  ? 'Pending'
                                  : row.status == 3
                                    ? 'Failed'
                                    : row.status == 4
                                      ? 'Refund'
                                      : 'Unknown'} {/* Label for unknown status */}
                            </Button>

                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleViewInvoice(row._id)} color="primary">
                              <Visibility />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleInquiry(row._id)}
                              sx={{ borderRadius: '20px' }}
                              disabled={loading2 || row.status == 2 || row.status == 4 }
                            >
                              Inquiry
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">No records found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Pagination
                  count={Math.ceil(totalCount / count)}
                  page={filterInitial.page + 1}
                  onChange={(event, value) => handlePageChange(event, value)}
                  color="primary"
                  size="large"
                />
              </Box>
            </>
          )}
        </Box>
      </StyledPaper>
      {billType === 'Credit Card' ? (
        <Creditcardtpinmodal
          show={modalShow}
          onHide={() => setModalShow(false)}
          paySubmit={paySubmit}
          loader1={loader1}
          initialValue={billDetails}
          editableAmount={editableAmount}
          originalAmount={billDetails?.amount}
          isAmountEdited={isAmountEdited}
        />

      ) : (
        <TipinModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          paySubmit={paySubmit}
          loader1={loader1}
          initialValue={billDetails}
        />
      )}
      <ToastContainer />
    </Container>
  );
};

export default BillPayment;