import { useEffect, useState } from "react";
import { Snackbar, Alert, TextField, Button } from "@mui/material";
import { dmtkycapi, dmtkycapink2 } from "../../../../api/login/Login";
import img1 from '../../../../asesets/logo/finger_success.gif'

import imgg1 from '../../../../asesets/Devices/iris.png'
import imgg2 from '../../../../asesets/Devices/mantra.png'
import imgg3 from '../../../../asesets/Devices/mantral1.png'
import imgg4 from '../../../../asesets/Devices/morpho.png'
import imgg5 from '../../../../asesets/Devices/morphol1.png'
import imgg6 from '../../../../asesets/Devices/precision.png'
import imgg7 from '../../../../asesets/Devices/sequgen.png'
import imgg8 from '../../../../asesets/Devices/startrek.png'
import imgg9 from '../../../../asesets/Devices/startrekl1.png'
import { Fingureprintdmtbnk2 } from "../../../../common/fingurePrintJs/Fingureprintdmtbnk2";


const mockData = [
    { id: '1', image: imgg1, name: 'Iris' },
    { id: '2', image: imgg2, name: 'Mantra' },
    { id: '3', image: imgg3, name: 'Mantra L1' },
    { id: '4', image: imgg4, name: 'Morpho' },
    { id: '5', image: imgg5, name: 'Morpho L1' },
    { id: '6', image: imgg6, name: 'Precision' },
    { id: '7', image: imgg7, name: 'Secugen' },
    { id: '8', image: imgg8, name: 'Startrek' },
    { id: '9', image: imgg9, name: 'Startrek L1' },
];

function DailyAuthdmtbank2({ api_id, mobile, toastSuccessMessage, toastSuccessMessageError, setBank2Kyc, SubmitCustomer , setBank2Create}) {
    const [aadhaarNumber, setAadhaarNumber] = useState("");
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [initialAuth, setInitialAuth] = useState({
        accessmodetype: "site",
        latitude: "",
        longitude: "",
        data: "",
        ipaddress: "",
        user_id: "",
        mobile: mobile,
        api_id: api_id  
    });

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                () => showSnackbar("Location not found. Please enable location and refresh the page.", "error")
            );
        } else {
            showSnackbar("Geolocation is not available in your browser.", "error");
        }
    }, []);

    useEffect(() => {
        setInitialAuth(prev => ({ ...prev, mobile: mobile, api_id: api_id }));
    }, [mobile, api_id]);

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const handleDeviceClick = (device) => {
        if (!position.latitude || !position.longitude) {
            showSnackbar('Location not found. Please enable location and refresh.', 'error');
            return;
        }
        setSelectedDevice(device);
        showSnackbar(`Selected device: ${device.name}`);
    };

    const Capturefingerherw = () => {
        if (!aadhaarNumber) {
            showSnackbar('Please fill in the Aadhaar number before capturing fingerprint.', 'error');
            return;
        }
        if (!position.latitude || !position.longitude) {
            showSnackbar('Cannot capture fingerprint without location. Please enable location and refresh.', 'error');
            return;
        }
        try {
            const fingerData = new Fingureprintdmtbnk2(
                'http://127.0.0.1:11100/rd/capture',
                result,
                api_id,
                mobile
            );
        } catch (error) {
            console.error(error);
            showSnackbar('Error initializing fingerprint capture', 'error');
        }
    };

    const result = (data) => {
        if (!data) {
            showSnackbar('Error: No data received from fingerprint capture', 'error');
            return;
        }

        const prolog = data.pid_data;
        if (!prolog) {
            showSnackbar('Error: No fingerprint data received', 'error');
            return;
        }

        try {
            const xmlParser = new DOMParser();
            const xmlStr = prolog + "<bookz/>";
            const xmlData = xmlParser.parseFromString(xmlStr, "application/xml");

            if (data.errCode === '0') {
                showSnackbar('Fingerprint Capture Successful');
            } else {
                showSnackbar(`Error in fingerprint capture: ${data.errCode}`, 'error');
                return;
            }

            if (data.httpSuccess === true) {
                
                const encodedXml = new XMLSerializer().serializeToString(xmlData)
                dataPost(encodedXml);
            } else {
                showSnackbar('HTTP request was not successful', 'error');
            }
        } catch (error) {
            console.error('Error processing fingerprint data:', error);
            showSnackbar('Error processing fingerprint data', 'error');
        }
    };

    const dataPost = async (fingerData) => {
        const requestData = {
            ...initialAuth,
            lat: position.latitude,
            long: position.longitude,
            data: fingerData,
            user_id: window.localStorage.getItem('userIdToken'),
            mobile: mobile,
            api_id: api_id,
            aadhaar_number: aadhaarNumber
        };

        try {
            // const res = await (api_id === "66bca8dd5727c7563ad6e317" ? dmtkycapi(requestData) : dmtkycapink2(requestData));
            const res = await  dmtkycapink2(requestData);
            if (res?.data?.statusCode === 200) {
                showSnackbar(res?.data?.message || 'Kyc Auth completed successfully');
                setBank2Kyc && setBank2Kyc(false);
                setBank2Create && setBank2Create(true)
                setAadhaarNumber("")

            } else {
                showSnackbar(res?.data?.message ||res?.message || "Something went wrong", 'error');
            }
        } catch (error) {
            console.error('Error in Kyc Auth:', error);
            showSnackbar('An error occurred during Kyc Auth', 'error');
        }
    };

    return (
        <>
            <div className="ContentArea">
                <div className="card">
                    <div className="card-header Register-header">
                        <span>Bank 2 Kyc Registration</span>
                    </div>
                    <div className="card-body">
                        <div className="form-row">
                            <TextField
                                label="Aadhaar Number"
                                variant="outlined"
                                value={aadhaarNumber}
                                onChange={(e) => setAadhaarNumber(e.target.value)}
                                fullWidth
                                style={{ marginBottom: '20px' }}
                            />
                         
                            <Button
                                variant="contained"
                                color="success"
                                onClick={Capturefingerherw}
                                style={{ height: '526px',marginTop:'4rem' }}
                            >
                                <img src={img1} alt="Capture Fingerprint" />
                            </Button>
                            <div className="col-lg-6 mt-3">
                                <div className="row">
                                    {mockData.map((item) => (
                                        <div className="col-lg-4 mt-2" key={item.id}>
                                            <div 
                                                className={`deviceImage ${selectedDevice?.id === item.id ? 'selected' : ''}`}
                                                onClick={() => handleDeviceClick(item)}
                                            >
                                                <img src={item.image} alt={item.name} />
                                                <p>{item.name}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}

export default DailyAuthdmtbank2;
