import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Pagination,
  TextField,
  useTheme,
  useMediaQuery,
  Autocomplete // Import Autocomplete component
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PendingIcon from '@mui/icons-material/Pending';
import { FaArrowDownLong, FaArrowUpLong, FaEyeSlash, FaEye } from "react-icons/fa6";
import Dashboardcard from './Dashboardcard';
const SummaryCard = ({ title, data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card elevation={3} sx={{ height: '100%', bgcolor: theme.palette.background.paper }}>
      <CardContent>
        <Typography variant={isMobile ? "h6" : "h5"} component="div" gutterBottom fontWeight="bold" color="primary">
          {title}
        </Typography>
        <Grid container spacing={2}>
          {['success', 'failed', 'pending'].map((status) => (
            <Grid item xs={12} key={status}>
              <Chip 
                icon={status === 'success' ? <TrendingUpIcon /> : status === 'failed' ? <TrendingDownIcon /> : <PendingIcon />}
                label={`${status.charAt(0).toUpperCase() + status.slice(1)}: ${data[status] ?? 'N/A'}`}
                color={status === 'success' ? "success" : status === 'failed' ? "error" : "warning"}
                sx={{ 
                  width: '100%', 
                  justifyContent: 'flex-start',
                  fontWeight: 'bold',
                  padding: theme.spacing(1.5),
                  '& .MuiChip-icon': {
                    color: 'inherit'
                  }
                }}
              />
            </Grid>
          ))} 
        </Grid>
      </CardContent>
    </Card>
  );
};

const PaginatedBusinessSummary = ({ data }) => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 4;
  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event, newValue) => {
    setSearchTerm(newValue || ''); // Set search term from selected value
    setPage(1); // Reset to the first page on search
  };

  const summaryData = [
    { title: "DMT Summary", data: data?.dmt?.[0] ?? {} },
    { title: "Payout Summary", data: data?.payout?.[0] ?? {} },
    { title: "CMS Summary", data: data?.cms ?? {} },
    { title: "Quick Dhan Summary", data: data?.quickDhan ?? {} },
    { title: "Adhaar Pay Summary", data: data?.adhaarPay ?? {} },
    { title: "Cash Withdrew Summary", data: data?.CashWithdrew ?? {} },
    { title: "Cash Deposit Summary", data: data?.CashDeposit ?? {} },
    ...(data?.services?.map((item) => ({
      title: item.service_name,
      data: {
        success: item.successTotalAmount,
        failed: item.failedTotalAmount,
        pending: item.pendingTotalAmount
      }
    })) ?? [])
  ];

  // Filter summary data based on the search term
  const filteredData = summaryData.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const displayedData = filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <Box sx={{ flexGrow: 1, padding: theme.spacing(3) }}>
     <Dashboardcard  data={data }/>
      <Typography variant="h4" gutterBottom fontWeight="bold" color="primary" align="center">
        Business Summary
      </Typography>

      {/* Search Bar with Autocomplete */}
      <Autocomplete
        freeSolo
        options={summaryData.map(item => item.title)} // List of service titles for suggestions
        onInputChange={handleSearchChange} // Handle search input changes
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search by title..."
            fullWidth
            sx={{ mb: 4 }}
          />
        )}
      />

      <Grid container spacing={3} sx={{ mb: 4 }}>
        {displayedData.length > 0 ? (
          displayedData.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <SummaryCard title={item.title} data={item.data} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary" align="center">
              No data found for "{searchTerm}"
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box display="flex" justifyContent="center">
        <Pagination 
          count={pageCount} 
          page={page} 
          onChange={handleChangePage} 
          color="primary" 
          size="large"
          disabled={pageCount <= 1}
        />
      </Box>
    </Box>
  );
};

export default PaginatedBusinessSummary;
