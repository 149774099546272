import { useEffect, useState } from "react"
import AepsOnBording from "../../../components/admin/aepsOnboarding/AepsOnBoarding"
import { UserPermissionAeps, aepsOnboard,UserPermissionAepswithuiqueid  } from "../../../api/login/Login"
import PermissionAepsOnboarding from "../../../components/admin/aepsOnboarding/permissionAepsOnboarding/PermissionAepsOnboarding"
import SuggestOnboarding from "../../../components/admin/aepsOnboarding/suggetsOnboarding/SuggestOnboarding"
import { ToastContainer, toast } from "react-toastify"
import BankPermission from "../../../components/admin/aepsOnboarding/bankPermission/BankPermission"
import AuthResister from "../../../components/admin/aepsOnboarding/authResister/AuthResister"
import DailyAuth from "../../../components/admin/aepsOnboarding/dailyAuth/DailyAuth"
import CompleteOnBoarding from "../../../components/admin/aepsOnboarding/comleteOnBoarding/CompleteOnBoarding"
import Loader from "../../../common/loader/Loader"
import Bank4aepsverify from "./Bank4aepsverify"
import Instanpayonboarding from "../../../components/admin/aepsOnboarding/suggetsOnboarding/Instanpayonboarding"
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Modal } from '@mui/material';




function AepsOnBoardingPage({ walletShowHeader, profiledata }) {
  
  const [loading, setLoading] = useState(false);
  const [aepsType, setAepsType] = useState('Aeps2');
  const apiAepsType = aepsType === 'Aeps2' ? 'aeps2' : 'aeps4';
  const [isInstantpayOnBoarded, setIsInstantpayOnBoarded] = useState(false);
  const [bankverify, setbankverify] = useState(false);
  const [bankServerDown, setBankServerDown] = useState(false);
  const [bank2, setbank2] = useState()
  const [bank3, setbank3] = useState()
  const [merchnadecode, setMerchantCode] = useState(null);
  const [changedevice,setChangeDevice] =useState(1)
  const [aepsData, setAepsData] = useState({
      Aeps2: {
          isPlanPurchased: false,
          isOnboarded: false,
          hasBankPermission: false,
          isAuthRegistered: false,
          hasDailyAuth: false,
          permissions: {
              balanceEnquiry: false,
              cashWithdrawal: false,
              miniStatement: false,
              aadhaarPay: false
          }
      },
      Aeps4: {
          isPlanPurchased: false,
          isOnboarded: false,
          hasBankPermission: false,
          isAuthRegistered: false,
          hasDailyAuth: false,
          permissions: {
              balanceEnquiry: false,
              cashWithdrawal: false,
              miniStatement: false,
              aadhaarPay: false
          }
      }
  });
  const [error, setError] = useState({
      show: false,
      message: '',
      type: 'error'
  });

  // Snackbar handlers
  const handleCloseSnackbar = () => {
      setError({ ...error, show: false });
  };

  const showError = (message, type = 'error') => {
      setError({
          show: true,
          message,
          type
      });
  };
  const showSuccess = (message, type = 'success') => {
    setError({
        show: true,
        message,
        type
    });
};

  // Helper function to check bank permissions
  const checkBankPermission = (aepsData, type) => {
      if (!aepsData?.bankActiveStatus) return false;
      
      return type === 'Aeps2' 
          ? (aepsData.bankActiveStatus.Bank2 || aepsData.bankActiveStatus.Bank3)
          : aepsData.bankActiveStatus.Bank4;
  };

  // Fetch permissions from API
  const fetchPermissions = async () => {
    setLoading(true);
    try {
        const [basicRes, cashWithdrawalRes, aadhaarPayRes] = await Promise.all([
            UserPermissionAeps(),                           // For basic, balance enquiry, and mini statement
            UserPermissionAepswithuiqueid(207),            // For cash withdrawal
            UserPermissionAepswithuiqueid(208)             // For aadhaar pay
        ]);

        setMerchantCode(basicRes.data.data.merchantCode);

        if (basicRes?.data?.statusCode == 200) {
            showSuccess(basicRes?.data?.message || basicRes?.message || 'Permissions fetched successfully');

            const data = basicRes?.data?.data;
            if (!data) {
                throw new Error('Invalid response data');
            }

            // Update AEPS data using basicRes for balance enquiry and mini statement
            const newAepsData = {
                Aeps2: {
                    isPlanPurchased: data.is_active && data.is_buy,
                    isOnboarded: data.Aeps2?.is_onboarding || false,
                    hasBankPermission: checkBankPermission(data.Aeps2, 'Aeps2'),
                    isAuthRegistered: data.Aeps2?.authRegistered || false,
                    hasDailyAuth: data.Aeps2?.dailyAuth || false,
                    permissions: {
                        // Use main UserPermissionAeps response for these
                        balanceEnquiry: data.is_active && data.is_buy,
                        miniStatement: data.is_active && data.is_buy,
                        // Use specific permission calls for these
                        cashWithdrawal: cashWithdrawalRes?.data?.data?.is_active && cashWithdrawalRes?.data?.data?.is_buy,
                        aadhaarPay: aadhaarPayRes?.data?.data?.is_active && aadhaarPayRes?.data?.data?.is_buy
                    }
                },
                Aeps4: {
                    isPlanPurchased: data.is_active && data.is_buy,
                    isOnboarded: data.Aeps4?.is_onboarding || false,
                    hasBankPermission: checkBankPermission(data.Aeps4, 'Aeps4'),
                    isAuthRegistered: data.Aeps4?.authRegistered || false,
                    hasDailyAuth: data.Aeps4?.dailyAuth || false,
                    permissions: {
                        // Use main UserPermissionAeps response for these
                        balanceEnquiry: data.is_active && data.is_buy,
                        miniStatement: data.is_active && data.is_buy,
                        // Use specific permission calls for these
                        cashWithdrawal: cashWithdrawalRes?.data?.data?.is_active && cashWithdrawalRes?.data?.data?.is_buy,
                        aadhaarPay: aadhaarPayRes?.data?.data?.is_active && aadhaarPayRes?.data?.data?.is_buy
                    }
                }
            };

            setAepsData(newAepsData);

            // Automatically select AEPS4 if it's onboarded and AEPS2 is not
            if (!newAepsData.Aeps2.isOnboarded && newAepsData.Aeps4.isOnboarded) {
                setAepsType('Aeps4');
            }
        } else if (basicRes?.data?.statusCode == '00127') {
            setBankServerDown(true);
        } else if (basicRes?.data?.statusCode == '007') {
            setbankverify(true);
            showError(basicRes?.data?.message || basicRes?.message);
        } else {
            showError(basicRes?.data?.message || 'Failed to fetch permissions');
        }
    } catch (error) {
        showError(error.message || 'Failed to fetch permissions');
    } finally {
        setLoading(false);
    }
};



  const handleAepsTypeChange = (event) => {
    const selectedAepsType = event.target.value;
    setAepsType(selectedAepsType);

    // Update isInstantpayOnBoarded based on selected AEPS type
    if (selectedAepsType === 'Aeps4') {
        setIsInstantpayOnBoarded(true);  // Set true when Aeps4 is selected
    } else {
        setIsInstantpayOnBoarded(false);  // Set false otherwise
    }
};

  // Handle onboarding process
  const handleOnboarding = async () => {
      setLoading(true);
      try {
          const data = {
              user_id: localStorage.getItem('userIdToken'),
              bank: apiAepsType
          };
          
          const response = await aepsOnboard(data);
          
          if (response?.data?.statusCode === 200) {
              window.open(response.data.data.url, '_blank');
              showError( response?.data?.message || response?.message ||'Onboarding successful', 'success');
              await fetchPermissions();
          } else {
              throw new Error(response?.data?.message || 'Onboarding failed');
          }
      } catch (error) {
          showError(error.message);
      } finally {
          setLoading(false);
      }
  };

  useEffect(() => {
      fetchPermissions();
  }, []);

  // Render the current step based on selected AEPS type
  const renderCurrentStep = () => {
    const currentAepsData = aepsData[aepsType];

    if (!currentAepsData.isPlanPurchased) {
        return <PermissionAepsOnboarding />;
    }

    if (!currentAepsData.isOnboarded) {
        return <SuggestOnboarding 
            onBoardingApiCall={handleOnboarding} 
            apiAepsType={apiAepsType} 
            isInstantpayOnBoarded={isInstantpayOnBoarded} 
            permissionAeps={fetchPermissions} 
            setbankverify={setbankverify}
            merchnadecode={merchnadecode}
        />;
    }

    if (!currentAepsData.hasBankPermission) {
        return (
            <BankPermission 
                profiledata={profiledata} 
                onDailyAuthComplete={() => fetchPermissions()} 
                apiAepsType={apiAepsType} 
                merchnadecode={merchnadecode}
            />
        );
    }

    if (!currentAepsData.isAuthRegistered) {
        return <AuthResister 
            apiAepsType={apiAepsType} 
            onDailyAuthComplete={() => fetchPermissions()} 
        />;
    }

    if (!currentAepsData.hasDailyAuth) {
        return <DailyAuth 
            onDailyAuthComplete={() => fetchPermissions()} 
            apiAepsType={apiAepsType} 
        />;
    }

    // Pass all permissions to CompleteOnBoarding
    return (
        <CompleteOnBoarding
        balanceEnquiryPermission={currentAepsData.permissions.balanceEnquiry}
        cashWithdrawalPermission={currentAepsData.permissions.cashWithdrawal}
        miniStatementPermission={currentAepsData.permissions.miniStatement}
        aadhaarPayPermission={currentAepsData.permissions.aadhaarPay}
            walletShowHeader={walletShowHeader}
            apiAepsType={apiAepsType}
            isInstantpayOnBoarded={isInstantpayOnBoarded}
        />
    );
};

  console.log("apiAepsType",apiAepsType)

  return (
      <div className="aeps-onboarding">
          {loading && <Loader />}
          
          <div className="aeps-type-selector" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',marginTop:'2%' }}>
    <FormControl component="fieldset" style={{ width: '100%', maxWidth: '400px' }}>
        <FormLabel>Select AEPS Type</FormLabel>
        <RadioGroup value={aepsType} onChange={handleAepsTypeChange} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControlLabel
                value="Aeps2"
                control={<Radio />}
                label={
                    <>
                        AEPS 2 
                        {aepsData.Aeps2.isOnboarded && (
                            <span style={{ color: 'green', marginLeft: '8px' }}>
                                ✔ (Onboarded)
                            </span>
                        )}
                    </>
                }
            />
            <FormControlLabel
                value="Aeps4"
                control={<Radio />}
                label={
                    <>
                        AEPS 4
                        {aepsData.Aeps4.isOnboarded && (
                            <span style={{ color: 'green', marginLeft: '8px' }}>
                                ✔ (Onboarded)
                            </span>
                        )}
                    </>
                }
            />
        </RadioGroup>
    </FormControl>
</div>

          {renderCurrentStep()}
          <Bank4aepsverify
                show={bankverify}
                onHide={() => setbankverify(false)} 
                permissionAeps={fetchPermissions}
            />

            {bankServerDown && (
                <div style={{ 
                    textAlign: 'center', 
                    padding: '20px', 
                    backgroundColor: '#ffcccc', 
                    borderRadius: '5px',
                    margin: '20px auto',
                    maxWidth: '400px'
                }}>
                    <h2 style={{ color: '#cc0000' }}>Bank Server is Down</h2>
                    <p>We apologize for the inconvenience. Please try again later.</p>
                </div>
            )}
          <Snackbar
              open={error.show}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={error.message}
              action={
                  <IconButton size="small" onClick={handleCloseSnackbar}>
                      <CloseIcon />
                  </IconButton>
              }
          />
      </div>
  );
}

export default AepsOnBoardingPage;