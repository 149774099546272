import React, { useState, useEffect, useCallback } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { Visibility, Search, Clear } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { bbps_txnElectri, dthrechargeOperators, getBbpsInquiryRechrge } from "../../../../../../api/login/Login";

const MobileRechargeList = () => {
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    txn_id: '',
    start_date: new Date().toISOString().substr(0, 10),
    end_date: new Date().toISOString().substr(0, 10),
    biller_id: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterData(prev => ({ ...prev, [name]: value }));
  };

  const fetchOperators = useCallback(async () => {
    try {
      const res = await dthrechargeOperators();
      if (res?.data?.error === false) {
        const mappedOperators = res?.data?.data?.operators?.map(item => ({
          ...item,
          value: item._id,
          label: item.name
        }));
        setOperators(mappedOperators);
      } else {
        toast.error(res?.data?.message || "Failed to fetch operators");
      }
    } catch (error) {
      console.error("Error fetching operators:", error);
      toast.error("Failed to fetch operators");
    }
  }, []);

  const fetchTransactions = useCallback(async (pageNumber) => {
    setLoading(true);
    const apiPage = pageNumber - 1; // Adjust page number for API
    try {
      const res = await bbps_txnElectri({
        ...filterData,
        biller_id: selectedOperator?.value || '',
        count: 10,
        category: '6683af280b801cb1adc7407a',
        page: apiPage,
        user_id: localStorage.getItem('userIdToken')
      });
      setTotalCount(res?.data?.totalCount);
      setTotalAmount(res?.data?.totalAmount);
      setTransactions(res?.data?.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.error("Failed to fetch transactions");
    }
    setLoading(false);
  }, [filterData, selectedOperator]);

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  useEffect(() => {
    fetchTransactions(page);
  }, [fetchTransactions, page]);

  const handleSearch = () => {
    setPage(1);
    fetchTransactions(1);
  };

  const handleReset = () => {
    setFilterData({
      txn_id: '',
      start_date: "",
      end_date: "",
      biller_id: '',
       category: '6683af280b801cb1adc7407a'
    });
    setSelectedOperator(null);
    setPage(1);
    fetchTransactions(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleViewInvoice = (id) => {
    navigate(`/bill-invoice/${id}`);
  };

  const handleInquiry = async (txnId) => {
    try {
      setLoading(true);
      const response = await getBbpsInquiryRechrge(txnId);
      const { error, message: responseMessage } = response?.data;
      
      if (error === false) {
        toast.success(responseMessage);
      } else {
        toast.error(responseMessage || "Inquiry failed");
      }
    } catch (error) {
      console.error('Inquiry error:', error);
      toast.error(error.response?.data?.message || 'An error occurred during inquiry');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>Recent Transactions</Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            name="start_date"
            value={filterData.start_date}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            label="End Date"
            type="date"
            name="end_date"
            value={filterData.end_date}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            label="Transaction ID"
            name="txn_id"
            value={filterData.txn_id}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Select
            value={selectedOperator}
            onChange={setSelectedOperator}
            options={operators}
            placeholder="Select Operator"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            startIcon={<Search />}
            fullWidth
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="outlined"
            onClick={handleReset}
            startIcon={<Clear />}
            fullWidth
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Biller ID</TableCell>
              <TableCell>Operator Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Inquiry</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell>{index + 1 + ((page - 1) * 10)}</TableCell>
                <TableCell>{item.createdAt}</TableCell>
                <TableCell>{item.biller_id}</TableCell>
                <TableCell>{item.operator_name}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color={item.status === 2 ? "success" : item.status === 1 ? "warning" : "error"}
                    size="small"
                  >
                    {item.status === 2 ? "Success" : item.status === 1 ? "Pending" : "Failed"}
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleViewInvoice(item._id)}>
                    <Visibility />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleInquiry(item._id)}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : "Inquiry"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} align="right" sx={{ fontWeight: 'bold' }}>Total Amount:</TableCell>
              <TableCell colSpan={4} sx={{ fontWeight: 'bold' }}>{totalAmount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
        <Grid item>
          <Typography variant="body2">
            Total {totalCount} entries
          </Typography>
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(totalCount / 10)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MobileRechargeList;