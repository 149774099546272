import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
  isAndroid,
  isIOS,
  deviceDetect,
  isWindows,
  isMacOs,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge
} from 'react-device-detect';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import forge from 'node-forge';
import axios from 'axios';
import { baseUrl } from '../../../../../../baseUrl';

// Securely store and retrieve these keys in production
const AES_KEY = "03ab4a43059239fd13d75987d7dfafda51467e871b87bd3847f01474116d311c";
const AES_IV = "3529f86c41ab1bb8b8f6d788cbb3f6f9";
const RSA_PUBLIC_KEY = "-----BEGIN PUBLIC KEY-----\nMFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBBUuux5GAG/Fm9YoeGJeYr5qT6WFXga3/\npV935e83f01KK+1E/Av6myqwYsmqRG/FuCFeMyv+mlXGIKu5fO3+X18CAwEAAQ==\n-----END PUBLIC KEY-----\n";
const RSA_PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIBVgIBADANBgkqhkiG9w0BAQEFAASCAUAwggE8AgEAAkEFS67HkYAb8Wb1ih4Y\nl5ivmpPpYVeBrf+lX3fl7zd/TUor7UT8C/qbKrBiyapEb8W4IV4zK/6aVcYgq7l8\n7f5fXwIDAQABAkECYINWbxgK8uOdKV7y+PD/OapU4m2t9tU+fMjLvAG0MZHt8y8N\nVwgmKSIKJhIJCJhi0yQ8OfktrXqR2WWPSB2KcQIhA4H1Vkwe1Yy+SxqYIV80kMIg\nleWhKjT0n9ulhPWR/sUpAiEBgn5BMwTzTYFJ2XQvanpLxm5NYKjm3ZtZbbDq6KfV\nSUcCIQGSBGHfcIqRGX0MagG+2wzYLLiPdwkrbj3Hm6IcQ4G5iQIgcUOay97Zho8r\nG0UHWEb6ye4tUvRso+kQ7ip4zTHSOaUCIQCPYrDcX7TBHjL8ymTorezbRm4AcT5l\nL2o97zb984pyGg==\n-----END PRIVATE KEY-----\n";
const encryptAES = (text) => {
  if (typeof text !== 'string' || text.length === 0) {
    throw new Error('AES encryption failed: Input must be a non-empty string');
  }
  const cipher = forge.cipher.createCipher('AES-CBC', forge.util.hexToBytes(AES_KEY));
  cipher.start({ iv: forge.util.hexToBytes(AES_IV) });
  cipher.update(forge.util.createBuffer(text));
  cipher.finish();
  return cipher.output.toHex();
};

const encryptRSA = (plainText) => {
  if (typeof plainText !== 'string' || plainText.length === 0) {
    throw new Error('RSA encryption failed: Input must be a non-empty string');
  }
  try {
    const publicKey = forge.pki.publicKeyFromPem(RSA_PUBLIC_KEY);
    const encrypted = publicKey.encrypt(plainText, 'RSA-OAEP');
    return forge.util.encode64(encrypted);
  } catch (error) {
    console.error('RSA encryption failed:', error);
    throw new Error(`RSA encryption failed: ${error.message}`);
  }
};

const decryptRSA = (encryptedText) => {
  if (typeof encryptedText !== 'string' || encryptedText.length === 0) {
    throw new Error('RSA decryption failed: Input must be a non-empty string');
  }
  try {
    const privateKey = forge.pki.privateKeyFromPem(RSA_PRIVATE_KEY);
    const decrypted = privateKey.decrypt(forge.util.decode64(encryptedText), 'RSA-OAEP');
    return decrypted;
  } catch (error) {
    console.error('RSA decryption failed:', error);
    throw new Error(`RSA decryption failed: ${error.message}`);
  }
}
const setEncrypTpin = (enData, rsaendata) => {
  return axios.post(`${baseUrl}tpin/addTpin`, enData, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      rsaendata: rsaendata,
    }
  });
};

const DeviceInfo = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [deviceId, setDeviceId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tpin, setTpin] = useState('');
  const [confirmTpin, setConfirmTpin] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const deviceInfo = deviceDetect();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch IP Address
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        setIpAddress(ipData.ip);

        // Fetch Device ID from FingerprintJS
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        setDeviceId(result.visitorId);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch device information');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getDeviceType = () => {
    if (isWindows) return 'Windows PC';
    if (isMacOs) return 'MacBook or iMac';
    if (isAndroid) return 'Android Device';
    if (isIOS) return 'iOS Device';
    if (isTablet) return 'Tablet';
    if (isMobile) return 'Mobile Device';
    if (isBrowser) return 'Desktop Browser';
    return 'Unknown Device';
  };

  const getBrowserType = () => {
    if (isChrome) return 'Chrome';
    if (isFirefox) return 'Firefox';
    if (isSafari) return 'Safari';
    if (isOpera) return 'Opera';
    if (isIE) return 'Internet Explorer';
    if (isEdge) return 'Microsoft Edge';
    return 'Unknown Browser';
  };

  const formatTpin = (input) => {
    if (typeof input !== 'string' || input.length < 4) {
      throw new Error('TPIN must be a string with at least 4 characters.');
    }
    const numericTpin = parseInt(input, 10);
    if (isNaN(numericTpin)) {
      throw new Error('TPIN must be a valid number.');
    }
    return `PP@${input.slice(0, 2)}PS${input.slice(2)}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      if (!tpin || tpin.length < 4) {
        throw new Error('TPIN must be at least 4 digits long.');
      }

      if (tpin !== confirmTpin) {
        throw new Error('TPIN and Confirm TPIN do not match.');
      }

      const formattedTpin = formatTpin(tpin);
      
      const bodyData = {
        tpin: tpin,
        cTpin: confirmTpin, // Including confirm TPIN in the encrypted body
        userId: window.localStorage.getItem('userToken'),
        deviceId: deviceId || 'Unknown',
        ipAddress: ipAddress || 'Unknown',
        deviceType: getDeviceType(),
        device_info: JSON.stringify(deviceInfo),
        long: '', // Add actual longitude if available
        lat: '', // Add actual latitude if available
        expiry_date: "",
        expiry_status: true,
        fingerprint: deviceId || 'Unknown' // Using deviceId as fingerprint
      };

      const encryptedBody = encryptAES(JSON.stringify(bodyData));
      const rsaendata = encryptRSA(formattedTpin);
      console.log('rsaendata Response:', rsaendata);

      const response = await setEncrypTpin(
        { enData: encryptedBody },
        rsaendata
      );

      console.log('API Response:', response.data);
      const decryptedTpin = decryptRSA(rsaendata);
      console.log('Decrypted TPIN:', decryptedTpin);
      // Handle successful submission (e.g., show success message, navigate to next page)
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setError(error.message || 'Failed to set TPIN. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return <div>Loading device information...</div>;
  }

  return (
    <div>
    

    <form onSubmit={handleSubmit}>
      <input
        type="number"
        value={tpin}
        onChange={(e) => setTpin(e.target.value)}
        placeholder="Enter TPIN"
        maxLength={4}
      />
      <input
        type="number"
        value={confirmTpin}
        onChange={(e) => setConfirmTpin(e.target.value)}
        placeholder="Confirm TPIN"
        maxLength={4}
      />
      
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </form>
    {error && <p style={{ color: 'red' }}>{error}</p>}

    {/* <BrowserView>
      <p>This content is visible only on browsers</p>
    </BrowserView>
    
    <MobileView>
      <p>This content is visible only on mobile devices</p>
    </MobileView> */}
  </div>
  );
};

export default DeviceInfo;