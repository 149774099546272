import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { checkOnboarding } from '../../../../api/login/Login';

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  let cleanedNumber = phoneNumber.startsWith('+91') ? phoneNumber.slice(3) : phoneNumber;
  cleanedNumber = cleanedNumber.replace(/\D/g, '');
  return cleanedNumber;
};

const BankPermission = ({ bank1, bank2, profiledata, onDailyAuthComplete ,apiAepsType,merchnadecode }) => {
  const [selectedBank, setSelectedBank] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const referid = profiledata?.refer_id;
  const phoneNumber = localStorage.getItem('regisNumber');
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };

  const handleCheckClick = async () => {
    if (!selectedBank) {
      setSnackbar({ open: true, message: 'Please select a bank', severity: 'error' });
      return;
    }

    setIsLoading(true);

    try {
      const payload = {
        merchantcode: merchnadecode,
        mobile: formattedPhoneNumber,
        pipe: selectedBank,
        bank:apiAepsType,
      
      };

      const response = await checkOnboarding(payload);
      if (response?.data?.statusCode == 200) {
        setSnackbar({ open: true, message: response?.data?.message || 'Check completed successfully', severity: 'success' });
        if (onDailyAuthComplete) {
          onDailyAuthComplete();
        }
      } else {
        setSnackbar({ open: true, message: response?.data?.message || response?.message || 'Check failed', severity: 'error' });
      }
    } catch (err) {
      setSnackbar({ open: true, message: 'An error occurred while processing your request', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  console.log("payloadtosend1", referid);
  console.log("payloadtosend2", formattedPhoneNumber);

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        AEPS Onboarding Bank Select
      </Typography>
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Select Bank</InputLabel>
        <Select
          value={selectedBank}
          onChange={handleBankChange}
          label="Select Bank"
          sx={{
            backgroundColor: 'white',
            '&:hover': { backgroundColor: 'white' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(0, 0, 0, 0.23)' },
            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(0, 0, 0, 0.87)' },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
            color: 'text.primary'
          }}
         
        >
          <MenuItem value="bank2"  >bank2</MenuItem>
          <MenuItem value="bank3" >bank3</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        startIcon={<CheckCircleOutlineIcon />}
        onClick={handleCheckClick}
        disabled={isLoading || !selectedBank}
        sx={{ mt: 6 }}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Check'}
      </Button>

      {(bank1 || bank2) && (
        <Box sx={{ mt: 2, p: 1, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="body2">
            <strong>Note:</strong> {bank1 || bank2}
          </Typography>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BankPermission;