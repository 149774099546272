import { Pagination, Button, message } from "antd";
import { useEffect, useState } from "react"
import { getCmsData, getCmsAllData,getCmsInquiry } from "../../../api/login/Login"
import Loader from "../../../common/loader/Loader"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Link, useNavigate } from "react-router-dom";
import { EyeOutlined } from '@ant-design/icons';


function AirtelCmsReport() {

    const navigate = useNavigate();
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().substr(0, 10);
    };

    const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [count] = useState(10);  // count is now a number 10
    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(null)
    const [data, setData] = useState(null)
    const [allData, setAllData] = useState(null)
    const [excelData, setExcelData] = useState(null);
    const [filterInitial, setFilterInitial] = useState({
        customer_mobile: '',
        end_date: getCurrentDate(),
        start_date: getCurrentDate(),
        txn_id: '',
    })

    const handleChange = (e) => {
        const clone = { ...filterInitial }
        const value = e.target.value
        const name = e.target.name
        clone[name] = value
        setFilterInitial(clone)
    }

    const getCmsReport = async (input) => {
        console.log('getCmsReport called with input:', input);
        setLoading(true);
        const params = new URLSearchParams({
            ...filterInitial,
            count: count,
            page: input,
        })
        console.log('API params:', params.toString());
        try {
            console.log('Calling getCmsData with params:', params.toString());
            const res = await getCmsData(params);
            console.log('API response:', res);
            if (res && res.data && res.data.data) {
                setTotalCount(res.data.totalCount);
                setData(res.data.data);
            } else {
                console.error('Unexpected API response structure:', res);
            }
            // allDataCmsReport();
        } catch (error) {
            console.error('API error:', error);
        }
        setLoading(false);
    };
    
    const onChangeVal = (e) => {
        setPage(e - 1)
        getCmsReport(e - 1)
    };

    const ResetData = async () => {
        setLoading(true);
        const params = new URLSearchParams({
            count: count,  // count is a number 10
            page: 0,
            end_date: '',
            start_date: '',
            customer_mobile: '',
            txn_id: '',
        })
        try {
            const res = await getCmsData(params);
            setTotalCount(res?.data?.totalCount);
            setData(res?.data?.data);
            setFilterInitial({
                end_date: '',
                start_date: '',
                customer_mobile: '',
                txn_id: '',
            });
            getCmsReport(0);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const allDataCmsReport = async () => {
        const params = new URLSearchParams({
            ...filterInitial,
            count: count,  // count is a number 10
            page: page,
        })
        try {
            const res = await getCmsAllData(params);
            setAllData(res?.data?.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getCmsReport(0)
    }, [])
    const handleViewInvoice = (id) => {
        navigate(`/cms-invoice/${id}`);
    };

    const handleInquiry = async (id) => {
        setLoading2(true);  // Set loading to true when the request starts
        try {
            const response = await getCmsInquiry(id);
            const statusCode = response?.data?.statusCode;
            const responseMessage = response?.data?.message || response?.message;

            switch (statusCode) {
                case 200:
                    message.success(responseMessage);
                    break;
                case 300:
                    message.info(responseMessage);
                    break;
                case 400:
                    message.error(responseMessage);
                    break;
                default:
                    message.warning(responseMessage);
            }
        } catch (error) {
            console.error("Inquiry error:", error);
            message.error("Failed to perform inquiry");
        } finally {
            setLoading2(false);  // Set loading to false when the request completes
        }
    };

    const getStatusText1 = (status) => {
        switch(status) {
            case 1: return "Pending";
            case 2: return "Success";
            case 3: return "Failed";
            case 4: return "Refunded";
            case 5: return "Not Initiate";
            default: return "Unknown";
        }
    };
    const getStatusText = (status) => {
        switch (status) {
            case 1: return { text: "Pending", className: "bg-warning text-white" };
            case 2: return { text: "Success", className: "bg-success text-white" };
            case 3: return { text: "Failed", className: "bg-danger text-white" };
            case 4: return { text: "Refunded", className: "bg-secondary text-white" };
            case 5: return { text: "Not Initiate", className: "bg-secondary text-white" };
            default: return { text: "Unknown", className: "bg-secondary text-white" };
        }
    };


    const prepareExcelDownload = async () => {
        setLoading(true);
        const clone = new URLSearchParams({
            ...filterInitial,
            count: count, 
            page: page,
        })
        try {
            const res = await getCmsAllData(clone);
            const data = res?.data?.data?.dashboard;
            
            if (!data || data.length === 0) {
                message.error("No data available to export.");
                setLoading(false);
                return;
            }

            setExcelData(data);
            
            // Use setTimeout to ensure the state is updated and the table is rendered
            setTimeout(() => {
                document.getElementById('test-table-xls-button').click();
                setLoading(false);
                message.success("Excel file download initiated.");
            }, 100);

        } catch (error) {
            console.error("Error preparing data for export:", error);
            message.error("Failed to prepare data for export. Please try again.");
            setLoading(false);
        }
    };
    
    useEffect(() => {
        // Trigger API call when filterInitial changes
        getCmsReport(0);
    }, [filterInitial]); // Dependency on filterInitial
    return (
        <>
            {loading && <Loader />}
            <div className="PageHeading">
                <h1>CMS Report</h1>
            </div>
            <div className="ContentArea">
                <div className="card">
                    <div className="card-header"><span>Filter</span></div>
                    <div className="card-body">
                        <form action="" method="get" name="frmReport" id="frmReport">
                            <input type="hidden" id="hidID" name="hidID" />
                            <div className="form-row" style={{ alignItems: 'end' }}>
                                <div className="form-group col-md-4">
                                    <label htmlFor="txtUserId">Start Date</label>
                                    <input type="date" name="start_date" id="account_no" className="form-control" max={currentDate} defaultValue={currentDate} value={filterInitial.start_date} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="txtUserId">End Date</label>
                                    <input type="date" name="end_date" id="account_no" max={currentDate} defaultValue={currentDate} className="form-control" value={filterInitial.end_date} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="customer_mobile">Customer Mobile</label>
                                    <input type="text" name="customer_mobile" id="customer_mobile" className="form-control" placeholder="Enter Customer Mobile" value={filterInitial.customer_mobile} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="txn_id">Txn Id</label>
                                    <input type="text" name="txn_id" id="txn_id" className="form-control" placeholder="Enter Txn Id" value={filterInitial.txn_id} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCmsReport(0)}>Search</button>
                                    <button type="button" className="btn btn-light" onClick={ResetData}>Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card mt-4" style={{ overflow: 'auto' }}>
                    <div className="card-body">
                        <div id="myTable_wrapper" className="dataTables_wrapper no-footer">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="dataTables_length mb-3" id="myTable_length">
                                <Button
                                    className="btn btn-success cusxel"
                                    onClick={prepareExcelDownload}
                                    disabled={loading}
                                    style={{
                                        backgroundColor: '#10b981',      // Modern green color
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '8px',
                                        padding: '10px 24px',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        cursor: 'pointer',
                                        minWidth: '160px',
                                        height: '42px',
                                        transition: 'all 0.2s ease',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        opacity: loading ? 0.7 : 1,      // Dimmed when loading
                                        transform: loading ? 'none' : 'translateY(0)',
                                        ':hover': {
                                            backgroundColor: '#059669',   // Darker green on hover
                                            transform: 'translateY(-1px)',
                                        },
                                        ':active': {
                                            transform: 'translateY(0)',
                                        },
                                        ':disabled': {
                                            backgroundColor: '#9ca3af',   // Gray when disabled
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                >
                                    Download Excel sheet
                                </Button>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button d-none"
                                    table="table-to-xlsx"
                                    filename="Cms_Report"
                                    sheet="Sheet1"
                                    buttonText="Download Excel"
                                />
                                </div>
                            </div>
                            <div id="myTable_processing" className="dataTables_processing" style={{ display: 'none' }}>Processing...</div>
                            <table className="table table-striped table-bordered table-hover display dataTable no-footer dtr-inline collapsed" id="myTable" role="grid" aria-describedby="myTable_info" >
                <thead>
                    <tr role="row">
                        <th className="sorting">S.No</th>
                        <th className="sorting">Created Date</th>
                        <th className="sorting">Txn Id</th>
                        <th className="sorting">Biller Id</th>
                        <th className="sorting">Biller Name</th>
                        <th className="sorting">Mobile No</th>
                        <th className="sorting">Amount</th>
                        <th className="sorting">Ackno</th>
                        <th className="sorting">Unique Id</th>
                        <th className="sorting">Status</th>
                        <th className="sorting">Invoice</th>
                        <th className="sorting"> Inquiry Status</th>
                        <th className="sorting">Dispute</th>
                    </tr>
                </thead>
                <tbody>
                {data && Array.isArray(data) && data.length > 0 ? (
    data.map((item, i) => (
        <tr className="odd" key={item?._id}>
            <td>{(i + 1) + (page * count)}</td>
            <td>{item?.createdAt}</td>
            <td>{item?.txn_id}</td>
            <td>{item?.biller_id}</td>
            <td>{item?.biller_name}</td>
            <td>{item?.mobile_no}</td>
            <td>{item?.amount}</td>
            <td>{item?.ackno}</td>
            <td>{item?.unique_id}</td>
            <td >
                <Button className={getStatusText(item?.status).className}>
    {getStatusText(item?.status).text}
    </Button>
</td>

            <td>
                <Button 
                    icon={<EyeOutlined />} 
                    onClick={() => handleViewInvoice(item?._id)}
                />
            </td>
            <td>
                <Button 
                    onClick={() => handleInquiry(item?._id)}
                    disabled={loading2 || item?.status == 2 || item?.status == 4 || item?.status == 5}
                >
                    Inquiry
                </Button>
            </td>
            <td valign="top" className="dataTables_empty">
                                                    <Link className="btn btn-primary" to={`/add-ticket/${item?._id}`} state={{ item}}>Dispute</Link>
                                                </td>
        </tr>
    ))
) : (
    <tr>
        <td colSpan="12">No data available</td>
    </tr>
)}

                </tbody>
            </table>
                            <div className="dataTables_info_page">
                                <div className="dataTables_info" id="empoloyees-tblwrapper_info" role="status" aria-live="polite">
                                    Total {totalCount} entries
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers" id="empoloyees-tblwrapper_paginate">
                                    <Pagination
                                        defaultCurrent={1}
                                        onChange={onChangeVal}
                                        total={totalCount}
                                        pageSize={count}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <table className="table table-striped table-bordered table-hover display dataTable no-footer dtr-inline collapsed d-none" id="table-to-xlsx" role="grid" aria-describedby="myTable_info" >
                <thead>
                    <tr role="row">
                    
                        <th className="sorting">Created Date</th>
                        <th className="sorting">Txn Id</th>
                        <th className="sorting">Biller Id</th>
                        <th className="sorting">Biller Name</th>
                        <th className="sorting">Mobile No</th>
                        <th className="sorting">Amount</th>
                        <th className="sorting">Ackno</th>
                        <th className="sorting">Unique Id</th>
                        <th className="sorting">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {excelData && excelData?.map((item) => (
                        <tr className="odd" key={item?._id}>
                            <td valign="top" className="dataTables_empty">{item?.createdAt}</td>
                            <td valign="top" className="dataTables_empty">{item?.txn_id}</td>
                            <td valign="top" className="dataTables_empty">{item?.biller_id}</td>
                            <td valign="top" className="dataTables_empty">{item?.biller_name}</td>
                            <td valign="top" className="dataTables_empty">{item?.mobile_no}</td>
                            <td valign="top" className="dataTables_empty">{item?.amount}</td>
                            <td valign="top" className="dataTables_empty">{item?.ackno}</td>
                            <td valign="top" className="dataTables_empty">{item?.unique_id}</td>
                            <td valign="top" className="dataTables_empty">{getStatusText1(item?.status)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default AirtelCmsReport