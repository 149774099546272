import React, { useState, useEffect, useRef, useCallback } from 'react';
import { verifyotprevsertrans, generateOtpForResendReverseTpin } from "../../../api/login/Login";
import { toast } from 'react-toastify';
import "./Userlist.css";
const OtpVerificationPage = ({ userId, onClose, longitude, latitude, transferAmount ,open, walletShowHeader ,fetchData,resetModalStates}) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [timeLeft, setTimeLeft] = useState(120);
  const [isTimerEnded, setIsTimerEnded] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const inputs = useRef([]);
  const timerRef = useRef(null);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const startTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);
    
    setTimeLeft(120);
    setIsTimerEnded(false);

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          setIsTimerEnded(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }, []);

  const resetTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);
    setTimeLeft(0);
    setIsTimerEnded(true);
  }, []);

  useEffect(() => {
    if (open) {
      startTimer();
    } else {
      resetTimer();
    }
    return () => clearInterval(timerRef.current);
  }, [open, startTimer, resetTimer]);

  const handleClose = useCallback(() => {
    resetTimer();
    setOtp(new Array(6).fill(''));
    setMessage('');
    setError('');
    onClose();
  }, [onClose, resetTimer]);

  const otpHandleChange = useCallback((index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1);
    setOtp(newOtp);

    if (value && index < 5 && inputs.current[index + 1]) {
      inputs.current[index + 1].focus();
    }
  }, [otp]);

  const handleKeyDown = useCallback((e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      // Move focus to the previous input and clear its value
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      inputs.current[index - 1].focus();
    }
  }, [otp]);

  const handleResendOTP = useCallback(async () => {
    setResendLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await generateOtpForResendReverseTpin({ token: userId, amount: Number(transferAmount), });
      if (response.status == 200) {
        setMessage('OTP has been resent successfully.');
        startTimer();
        setOtp(new Array(6).fill(''));
      } else {
        setError('Failed to resend OTP.');
      }
    } catch (error) {
      setError('Failed to resend OTP.');
    } finally {
      setResendLoading(false);
    }
  }, [userId, startTimer, transferAmount]);

  const handleSubmitOTP = useCallback(async () => {
    setLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await verifyotprevsertrans({ 
        token: userId,
        otp: otp.join(''),
        lat: latitude,
        long: longitude
      });
      if (response?.data?.statusCode == 200) {
        setMessage(response?.data?.message  || 'OTP verified successfully.');
        toast.success(response?.data?.message || 'OTP verified successfully.');
        onClose(); 
        if (walletShowHeader) walletShowHeader();
        if (fetchData) fetchData();
      if (resetModalStates) resetModalStates();

        // Call onClose to close the page/modal
      } else {
        setError(response?.data?.message ||  response?.data|| 'Failed to verify OTP.');
        toast.error(response?.data?.message || response?.data || 'Failed to verify OTP.');
      }
    } catch (error) {
      setError('Failed to verify OTP.');
      toast.error('Failed to verify OTP.');
    } finally {
      setLoading(false);
    }
  }, [userId, otp, onClose, latitude, longitude]);

  return (
    <div className={`modal fade modal-cssclasss mediaqurymodalpop ${open ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
    <div className="modal-dialog" role="document" style={{ zIndex: 1050 }}>
      <div className="modal-content modal-content-otp">
        <div className="modal-header text-center">
          <h5 className="modal-title modaltitle-otp">OTP Verification</h5>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </div>
        <div className="modal-body" style={{
          backgroundColor: '#ffffff',
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden',
        }}>
          {isTimerEnded ? (
            <>
              <p>Please Enter 6-digit Code Sent To Your Registered Mobile Number!</p>
              <button type="button" className="btn btn-resend-otp" onClick={handleResendOTP}>
                {resendLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Resend OTP'}
              </button>
            </>
          ) : (
            <>
              <p>Please Enter 6-digit Code Sent To Your Registered Mobile Number!</p>
              <div className="verification-timer">Verification Code: {formatTime(timeLeft)} seconds <span className="resend-otp"
                //  style={{
                //   cursor: isTimerEnded ? 'pointer' : 'default',
                //   color: isTimerEnded ? 'blue' : 'gray',
                //   pointerEvents: isTimerEnded ? 'auto' : 'none',
                // }} 
              onClick={handleResendOTP}>Resend OTP</span></div>
              <div className="otp-inputs">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength="1"
                    value={value}
                    onChange={(e) => otpHandleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(input) => (inputs.current[index] = input)}
                    className="otp-input"
                  />
                ))}
              </div>
              <button
                type="button"
                className="btn btn-submit-otp"
                onClick={handleSubmitOTP}
                disabled={otp.some(digit => digit === '') || loading}
              >
                {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit OTP'}
              </button>
              {message && <div className="alert alert-success mt-3">{message}</div>}
              {error && <div className="alert alert-danger mt-3">{error}</div>}
            </>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default OtpVerificationPage;