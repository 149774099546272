import { message } from 'antd';
import { getBbpsInquiry } from '../../../../api/login/Login';

// export const handleBbpsInquiry = async (txnId, setLoading) => {
//   try {
//     setLoading(true);
//     const response = await getBbpsInquiry(txnId);
    
//     const { error, statusCode, message: responseMessage } = response.data;

//     if (error === false) {
//       message.success(responseMessage || "Transaction Successful");
//     } else if (error === true) {
//       message.error(responseMessage || "Transaction Failed");
//     } else {
//       switch(statusCode) {
//         case 200:
//           message.success(responseMessage || "Successful");
//           break;
//         case 300:
//           message.info(responseMessage || "Pending");
//           break;
//         case 400:
//           message.error(responseMessage || "Failed");
//           break;
//         default:
//           message.warning(responseMessage || "Unknown status");
//       }
//     }
    
//     return response.data;
//   } catch (error) {
//     console.error('Inquiry error:', error);
//     message.error(error.response?.data?.message || 'An error occurred during inquiry');
//     throw error;
//   } finally {
//     setLoading(false);
//   }
// };

export const handleBbpsInquiry = async (txnId, setLoading, page,getTransitionReport) => {

  try {
    setLoading(true);
    const response = await getBbpsInquiry(txnId);
    
    const { error, statusCode } = response?.data;
    const responseMessage = response?.data?.message || response?.message;

    if (error === false) {
      message.success(responseMessage );
      if(getTransitionReport ){
        getTransitionReport(page)
      }
    } else if (error === true) {
      message.error(responseMessage );
    } else {
      switch(statusCode) {
        // case 200:
        //   message.success(responseMessage || "Successful");
        //   break;
        case 300:
          message.info(responseMessage );
          break;
        // case 400:
        //   message.error(responseMessage || "Failed");
        //   break;
        default:
          message.warning(responseMessage );
      }
    }
    
    return response.data;
  } catch (error) {
    console.error('Inquiry error:', error);
    message.error(error.response?.data?.message || 'An error occurred during inquiry');
    throw error;
  } finally {
    setLoading(false);
  }
}