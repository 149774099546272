import React from 'react';

import UserTransferBasic from '../moneyTransfer/userTransfer/UserTransferBasic';
import UserTransferWithOTP from '../moneyTransfer/userTransfer/UserTransferWithOTP';

const ConditionalUserTransfer = (props) => {
  const isBank3Api = props.initialCustomer?.api_id === "670e593e324d380f3be4fdb3";

  // If it's the special API ID, render code 1 (basic version)
  if (isBank3Api) {
    return (
      <UserTransferBasic {...props} />
     
    );
  }

  // Otherwise render code 2 (version with OTP)
  return (
    <UserTransferWithOTP {...props} />
  
  );
}

export default ConditionalUserTransfer;