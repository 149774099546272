// import $ from "jquery";

// export function Fingureprintdmtbnk2(capture_url, callbackFunction, apiId, mobileNumber,result ) {
//     var doc = document.implementation.createDocument("", "", null);
//     var pidOptionsElem = doc.createElement("PidOptions");
//     var optsElem = doc.createElement("Opts");

//     // Common attributes
//     optsElem.setAttribute("fCount", 1);
//     optsElem.setAttribute("fType", 2);
//     optsElem.setAttribute("iCount", 0);
//     optsElem.setAttribute("pCount", 0);
//     optsElem.setAttribute("format", 0);
//     optsElem.setAttribute("pidVer", "2.0");
//     optsElem.setAttribute("timeout", 10000);
//     optsElem.setAttribute("posh", "UNKNOWN");
//     optsElem.setAttribute("env", "P");

//     optsElem.setAttribute("wadh", "18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=");

//     pidOptionsElem.appendChild(optsElem);
//     doc.appendChild(pidOptionsElem);
   
//     var customerDataElem = doc.createElement("CustomerData");
//     pidOptionsElem.appendChild(customerDataElem);
//     console.log("customerDataElem",customerDataElem)


//     $.ajax({
//         url: capture_url,
//         type: "CAPTURE",
//         data: doc,
//         processData: false,
//         success: function (response) {
//             var doc2;
//             var result = {
//                 httpSuccess: true,
//                 mobileNumber: mobileNumber // Include mobile number in result
//             };

//             if (typeof response === "string") {
//                 doc2 = new DOMParser().parseFromString(response, "text/xml");
//                 result.pid_data = response;
//             } else {
//                 doc2 = response;
//                 result.pid_data = new XMLSerializer().serializeToString(response);
//             }
//             console.log("pid_data XMLbank2:", result.pid_data);

//             var resp = doc2.getElementsByTagName("Resp");
//             result.errCode = resp[0].getAttribute("errCode");

//             if (result.errCode != "0") {
//                 result.captureSuccess = false;
//                 result.errInfo = resp[0].getAttribute("errInfo");
//             } else {
//                 result.captureSuccess = true;
//                 result.captureQuality = parseInt(resp[0].getAttribute("qScore"));
//             }

//             callbackFunction(result);
//         },
//         error: function (jqXHR, textStatus, errorThrown) {
//             callbackFunction({
//                 httpSuccess: false,
//                 captureSuccess: false,
//                 textStatus: textStatus,
//                 errorThrown: errorThrown,
//                 mobileNumber: mobileNumber // Include mobile number even in error case
//             });
//         },
//     });
// }

// Modified DailyAUthdmt component implementation
// export function modifiedCaptureFingerprintHandler(initialCustomer) {
//     const capture_url = 'http://127.0.0.1:11100/rd/capture';
    
//     try {
//         const fingerData = new Capturefingerprintfordmt(
//             capture_url,
//             result,
//             initialCustomer.api_id,
//             initialCustomer.mobile
//         );
//     } catch (error) {
//         console.error("Error in fingerprint capture:", error);
//     }
// }






import $ from "jquery";

export function Fingureprintdmtbnk2(capture_url, callbackFunction, apiId, mobileNumber,result ) {
    var doc = document.implementation.createDocument("", "", null);
    var pidOptionsElem = doc.createElement("PidOptions");
    var optsElem = doc.createElement("Opts");

    // Common attributes
    optsElem.setAttribute("fCount", 1);
    optsElem.setAttribute("fType", 2);
    optsElem.setAttribute("iCount", 0);
    optsElem.setAttribute("pCount", 0);
    optsElem.setAttribute("format", 0);
    optsElem.setAttribute("pidVer", "2.0");
    optsElem.setAttribute("timeout", 10000);
    optsElem.setAttribute("posh", "UNKNOWN");
    optsElem.setAttribute("env", "P");
    // PIDOPTS = '<PidOptions ver=\"1.0\">' + '<Opts fCount=\"1\" fType=\"2\" iCount=\"\" iType=\"\" pCount=\"\" pType=\"\" format=\"0\" pidVer=\"2.0\" timeout=\"10000\" otp=\"\" wadh = \'18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=\' posh=\"\"/>' + '</PidOptions>';

    
        optsElem.setAttribute("wadh", "18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=");
  

    pidOptionsElem.appendChild(optsElem);
    doc.appendChild(pidOptionsElem);
    console.log("pidData",pidOptionsElem)

    // Add mobile number to the XML if provided
    // if (mobileNumber) {
    //     var customerDataElem = doc.createElement("CustomerData");
    //     // customerDataElem.setAttribute("mobile", mobileNumber);
    //     pidOptionsElem.appendChild(customerDataElem);
    // }

    $.ajax({
        url: capture_url,
        type: "CAPTURE",
        data: doc,
        processData: false,
        success: function (response) {
            var doc2;
            var result = {
                httpSuccess: true,
                mobileNumber: mobileNumber // Include mobile number in result
            };

            if (typeof response === "string") {
                doc2 = new DOMParser().parseFromString(response, "text/xml");
                result.pid_data = response;
                console.log("responsepid",response)
            } else {
                doc2 = response;
                result.pid_data = new XMLSerializer().serializeToString(response);
                console.log("responsepid",response)
            }

            console.log("pid_data:", result.pid_data);
    console.log("wadh:", optsElem.getAttribute("wadh"));

            var resp = doc2.getElementsByTagName("Resp");
            result.errCode = resp[0].getAttribute("errCode");

            if (result.errCode != "0") {
                result.captureSuccess = false;
                result.errInfo = resp[0].getAttribute("errInfo");
            } else {
                result.captureSuccess = true;
                result.captureQuality = parseInt(resp[0].getAttribute("qScore"));
            }

            callbackFunction(result);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            callbackFunction({
                httpSuccess: false,
                captureSuccess: false,
                textStatus: textStatus,
                errorThrown: errorThrown,
                mobileNumber: mobileNumber // Include mobile number even in error case
            });
        },
    });
}

// Modified DailyAUthdmt component implementation
// export function modifiedCaptureFingerprintHandler(initialCustomer) {
//     const capture_url = 'http://127.0.0.1:11100/rd/capture';
    
//     try {
//         const fingerData = new Capturefingerprintfordmt(
//             capture_url,
//             result,
//             initialCustomer.api_id,
//             initialCustomer.mobile
//         );
//     } catch (error) {
//         console.error("Error in fingerprint capture:", error);
//     }
// }