import React, { useState, useEffect, useRef, useCallback } from 'react';
import { verifyOtpForTpin, generateOtpForResendTpin } from "../../../api/login/Login";
import { toast } from 'react-toastify';
import './Dashboard.css';

const DashboardOtpModal = ({ 
  modalVisibility, 
  handleCloseModal3, 
  handleSubmitmodal3, 
  storedUserid,
  setModalVisibility,
  getUserTpinStatus
}) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [timeLeft, setTimeLeft] = useState(120);
  const [isTimerEnded, setIsTimerEnded] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const inputs = useRef([]);
  const timerRef = useRef(null);

  const formatTime = useCallback((time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }, []);

  const startTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);

    setTimeLeft(120);
    setIsTimerEnded(false);

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          setIsTimerEnded(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (modalVisibility.showModal3) {
      startTimer();
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [modalVisibility.showModal3, startTimer]);

  const otpHandleChange = useCallback((index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1);
    setOtp(newOtp);

    if (value && index < 5 && inputs.current[index + 1]) {
      inputs.current[index + 1].focus();
    }
  }, [otp]);

  const handleKeyDown = useCallback((index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0 && inputs.current[index - 1]) {
      inputs.current[index - 1].focus();
    }
  }, [otp]);

  const handleResendOTP = useCallback(async () => {
    setResendLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await generateOtpForResendTpin({ user_id: storedUserid });
      if (response.status === 200) {
        setMessage('OTP has been resent successfully.');
        startTimer();
        setOtp(new Array(6).fill(''));
      } else {
        setError('Failed to resend OTP.');
      }
    } catch (error) {
      setError('Failed to resend OTP.');
    } finally {
      setResendLoading(false);
    }
  }, [storedUserid, startTimer]);

  const handleSubmitOTP = useCallback(async () => {
    setLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await verifyOtpForTpin({ user_id: storedUserid, otp: otp.join('') });
      if (response?.data?.statusCode === 200) {
        setMessage('OTP verified successfully.');
        toast.success('OTP verified successfully.');
        getUserTpinStatus("OV");
        handleSubmitmodal3();
      } else {
        setError(response?.data?.message || 'Failed to verify OTP.');
        toast.error(response?.data?.message || 'Failed to verify OTP.');
      }
    } catch (error) {
      setError('Failed to verify OTP.');
      toast.error('Failed to verify OTP.');
    } finally {
      setLoading(false);
    }
  }, [storedUserid, otp, handleSubmitmodal3, getUserTpinStatus]);

  if (!modalVisibility.showModal3) return null;

  return (
    <div className="modal fade modal-cssclasss mediaqurymodalpop show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content modal-content-otp">
          <div className="modal-header text-center">
            <h5 className="modal-title modaltitle-otp">OTP Verification</h5>
          </div>
          <div className="modal-body">
            {isTimerEnded ? (
              <>
                <p>Please Enter 6-digit Code Sent To Your Registered Mobile Number !</p>
                <button type="button" className="btn btn-resend-otp" onClick={handleResendOTP}>
                  {resendLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Resend OTP'}
                </button>
              </>
            ) : (
              <>
                <p>Please Enter 6-digit Code Sent To Your Registered Mobile Number !!</p>
                <div className="verification-timer">Verification Code {formatTime(timeLeft)} <span className="resend-otp" 
                   style={{
                    cursor: isTimerEnded ? 'pointer' : 'default',
                    color: isTimerEnded ? 'blue' : 'gray',
                    pointerEvents: isTimerEnded ? 'auto' : 'none',
                  }}  onClick={handleResendOTP}>Resend OTP</span></div>
                <div className="otp-inputs">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="number"
                      maxLength="1"
                      value={value}
                      onChange={(e) => otpHandleChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      ref={(input) => (inputs.current[index] = input)}
                      className="otp-input"
                    />
                  ))}
                </div>
                <button
                  type="button"
                  className="btn btn-submit-otp"
                  onClick={handleSubmitOTP}
                  disabled={otp.some(digit => digit === '') || loading}
                >
                  {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit OTP'}
                </button>
                {message && <div className="alert alert-success mt-3">{message}</div>}
                {error && <div className="alert alert-danger mt-3">{error}</div>}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DashboardOtpModal);