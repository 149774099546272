import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function StatusChangeRequestHistory(props) {
    const [tpinError, setTpinError] = useState('');
    const [isValidTpin, setIsValidTpin] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        validateTpin(props.updateInitialMemberHistory.pin);
        validateForm();
    }, [props.updateInitialMemberHistory]);

    const validateForm = () => {
        const { pin, status, authority_remark } = props.updateInitialMemberHistory;
        const isValid = 
            isValidTpin && 
            status && 
            status !== 'Select Status' && 
            authority_remark && 
            authority_remark.trim() !== '';
        
        setIsFormValid(isValid);
    };

    const validateTpin = (tpin) => {
        if (!tpin) {
            setTpinError('TPIN is required');
            setIsValidTpin(false);
        } else if (tpin.length !== 4) {
            setTpinError('TPIN should be of four digits');
            setIsValidTpin(false);
        } else {
            setTpinError('');
            setIsValidTpin(true);
        }
    };

    const handleTpinChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); 
        if (value.length <= 4) {
            props.handleChangeUpdate({
                target: { name: 'pin', value }
            });
        }
        validateTpin(value);
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='text-align-center'>
                        Payment Request By Member
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row g-3'> {/* Added g-3 for consistent gap */}
                        <div className='col-lg-6 mb-3'>
                            <div className="form-group">
                                <label htmlFor="txtDate" className="form-label">Date :</label>
                                <input 
                                    className="form-control" 
                                    id="txtDate" 
                                    type="text" 
                                    name="date"
                                    value={props.updateInitialMemberHistory?.createdAt} 
                                    disabled 
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <div className="form-group">
                                <label htmlFor="txtAmount" className="form-label">Amount :</label>
                                <input 
                                    className="form-control" 
                                    id="txtAmount" 
                                    type="number" 
                                    name="amount" 
                                    value={props.updateInitialMemberHistory.amount} 
                                    disabled 
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <div className="form-group">
                                <label htmlFor="txtMemberRemark" className="form-label">Member Remarks :</label>
                                <input 
                                    className="form-control" 
                                    id="txtMemberRemark" 
                                    type="text" 
                                    name="remark" 
                                    value={props.updateInitialMemberHistory.remark} 
                                    disabled 
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <div className="form-group">
                                <label htmlFor="txtAuthorityRemark" className="form-label">Authority Remarks :</label>
                                <input 
                                    className="form-control" 
                                    id="txtAuthorityRemark" 
                                    type="text" 
                                    name="authority_remark" 
                                    value={props.updateInitialMemberHistory.authority_remark || ''} 
                                    onChange={props.handleChangeUpdate}
                                    placeholder="Enter authority remarks"
                                    required
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <div className="form-group">
                                <label htmlFor="txtPin" className="form-label">TPin :</label>
                                <input 
                                    className="form-control" 
                                    id="txtPin" 
                                    type="password" 
                                    name="pin" 
                                    placeholder='Enter TPin' 
                                    value={props.updateInitialMemberHistory?.pin || ''} 
                                    onChange={handleTpinChange} 
                                    maxLength={4}
                                    required
                                />
                                {tpinError && <div className="text-danger small mt-1">{tpinError}</div>}
                            </div>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <div className="form-group">
                                <label htmlFor="ddlStatus" className="form-label">Select Status :</label>
                                <select 
                                    className="form-select" 
                                    id="ddlStatus"
                                    name="status" 
                                    value={props.updateInitialMemberHistory.status || ''} 
                                    onChange={props.handleChangeUpdate}
                                    required
                                >
                                    <option value="" disabled>Select Status</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Close</Button>
                    <Button 
                        variant="primary" 
                        onClick={props.updateData} 
                        disabled={!isFormValid}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StatusChangeRequestHistory;