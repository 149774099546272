import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Paper,
  Tabs,
  Tab,
  Box,
  Grid,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Pagination
} from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import {
  Visibility,
  LiveTv,
  Payment,
  Refresh
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { billPayRecharge, dthPlanDetails, dthrechargeOperators } from "../../../../../api/login/Login";
import { useNavigate } from "react-router-dom";
import TipinModal from '../../../../../common/tipinModal/TipinModal';
import DthRechargeList from './dthRechargeList/DthRechageList';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  borderRadius: '20px',
  boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
  background: 'linear-gradient(145deg, #f3f4f6 0%, #fff 100%)',
}));

const AnimatedButton = styled(motion.div)({
  display: 'inline-block',
  width: '100%',
});

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  '&.Mui-selected': {
    color: '#1A237E',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

const DthRecharge = ({ walletShowHeader }) => {
  const [tabValue, setTabValue] = useState(0);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    billNumber: '',
    amount: ''
  });

  const [billDetails, setBillDetails] = useState(null);

  const fetchOperators = useCallback(async () => {
    try {
      const res = await dthrechargeOperators();
      if (res?.data?.error === false) {
        const mappedOperators = res?.data?.data?.operators?.map((item) => ({
          ...item,
          label: item.name
        }));
        setOperators(mappedOperators);
      } else {
        toast.error(res?.data?.message || "Failed to fetch operators");
      }
    } catch (error) {
      console.error("Error fetching operators:", error);
      toast.error("Failed to fetch operators");
    }
  }, []);

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  const handleOperatorChange = (event, newValue) => {
    setSelectedOperator(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleViewBill = async () => {
    setLoading(true);
    try {
      const res = await dthPlanDetails({
        mobile: formData.billNumber,
        operator: selectedOperator?._id
      });
      if (res?.data?.error === false) {
        setBillDetails(res?.data?.data);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message || "Failed to fetch bill details");
      }
    } catch (error) {
      console.error("Error viewing bill:", error);
      toast.error("Failed to fetch bill details");
    }
    setLoading(false);
  };
 

  const handlePay = async (tipin) => {
    setLoading(true);
    try {
      const res = await billPayRecharge({
        ...formData,
        tpin: tipin,
        user_id: window.localStorage.getItem('userIdToken'),
        rechargeId: selectedOperator?._id
      });
      if (res.data.error === false) {
        toast.success(res?.data?.message);
        setModalShow(false);
        if (walletShowHeader) walletShowHeader();
        resetForm();
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error("Error paying bill:", error);
      toast.error("An error occurred while processing your payment");
    }
    setLoading(false);
  };

  const resetForm = () => {
    setFormData({
      billNumber: '',
      amount: ''
    });
    setSelectedOperator(null);
    setBillDetails(null);
  };

  const handleViewInvoice = (id) => {
    navigate(`/bill-invoice/${id}`);
  };

  // Placeholder for fetching transactions
  const fetchTransactions = useCallback(async () => {
    // Implement your transaction fetching logic here
    // This is where you'd typically make an API call to get the transactions
    // For now, we'll just set some dummy data
    setTransactions([
      { _id: '1', date: '2023-09-28', dthNumber: '1234567890', operator: 'Tata Sky', amount: 500, status: 'Success' },
      { _id: '2', date: '2023-09-27', dthNumber: '9876543210', operator: 'Dish TV', amount: 300, status: 'Pending' },
      // ... more dummy data
    ]);
    setTotalCount(20); // Assuming there are 20 total transactions
  }, []);

  useEffect(() => {
    if (tabValue === 1) {
      fetchTransactions();
    }
  }, [tabValue, fetchTransactions]);

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        align="center"
        sx={{
          mt: 6,
          mb: 4,
          fontWeight: 'bold',
          color: '#1a237e',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LiveTv sx={{ fontSize: '2rem', mr: 2 }} />
        <span>DTH Recharge</span>
      </Typography>

      <StyledPaper elevation={3}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          centered
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
        >
          <StyledTab label="Recharge" />
          <StyledTab label="Recent Transactions" />
        </Tabs>

        <Box sx={{ p: 3 }}>
        {tabValue === 0 && (
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="DTH Number"
                  fullWidth
                  variant="outlined"
                  name="billNumber"
                  value={formData.billNumber}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={operators}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Operator"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  onChange={handleOperatorChange}
                  value={selectedOperator}
                />
              </Grid>
              {billDetails && (
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Amount"
                    fullWidth
                    variant="outlined"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    type="number"
                  />
                </Grid>
              )}
              {billDetails && (
                <Grid item xs={12}>
                  <Paper elevation={2} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                    <Typography variant="h6" gutterBottom>Bill Details</Typography>
                    <Grid container spacing={2}>
                      {Object.entries(billDetails).map(([key, value]) => (
                        <Grid item xs={6} key={key}>
                          <Typography variant="body2"><strong>{key}:</strong> {value}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} container spacing={2} justifyContent="center">
                {!billDetails && (
                  <Grid item xs={12} sm={4}>
                    <AnimatedButton whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleViewBill}
                        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Visibility />}
                        disabled={loading || !formData.billNumber || !selectedOperator}
                        fullWidth
                        size="large"
                        sx={{ py: 1.5, borderRadius: '12px' }}
                      >
                        {loading ? 'Processing...' : 'View Bill'}
                      </Button>
                    </AnimatedButton>
                  </Grid>
                )}
                {billDetails && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <AnimatedButton whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setModalShow(true)}
                          startIcon={<Payment />}
                          disabled={loading || !formData.amount}
                          fullWidth
                          size="large"
                          sx={{ py: 1.5, borderRadius: '12px' }}
                        >
                          Pay Now
                        </Button>
                      </AnimatedButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AnimatedButton whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={resetForm}
                          startIcon={<Refresh />}
                          fullWidth
                          size="large"
                          sx={{ py: 1.5, borderRadius: '12px' }}
                        >
                          Reset
                        </Button>
                      </AnimatedButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {tabValue === 1 && (
            <>
            <DthRechargeList/>
            </>
              
          )}
        </Box>
      </StyledPaper>

      <TipinModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        submitata={handlePay}
        loader1={loading}
        initialData={formData}
      />

      <ToastContainer />
    </Container>
  );
};

export default DthRecharge;