import { Nav, Tab, Tabs } from "react-bootstrap";
import BlanceEnquiry from "../../../../components/admin/aepsOnboarding/comleteOnBoarding/blanceEnquiry/BlanceEnquiry";
import { useState, useEffect } from "react";
import CashDeposit from "../../../../components/admin/cashDiposit/CashDeposit";
import { ToastContainer } from "react-toastify";

function CasDepositTab({ isInstantpayOnBoarded, apiAepsType, walletShowHeader }) {
    const [active, setActive] = useState(true);
    const [activeKey, setActiveKey] = useState("Balance Enquiry");

    // Set initial active tab based on availability
    useEffect(() => {
        if (!isInstantpayOnBoarded) {
            // When not onboarded, Cash Deposit is available
            setActiveKey("Cash Deposit");
        } else {
            // When onboarded, only Balance Enquiry is available
            setActiveKey("Balance Enquiry");
        }
    }, [isInstantpayOnBoarded]);

    const handleTabSelect = (key) => {
        setActiveKey(key);
        setActive(false);
    };

    return (
        <>
            <div className="ContentArea">
                <div className="card">
                    <div className="card-header">
                        <span>AEPS Services</span>
                    </div>
                    <div className="row p-4">
                        <div className="col-lg-12">
                            <div className="aeps-tab-section">
                                <Tabs
                                    activeKey={activeKey}
                                    id="aeps-services-tabs"
                                    className="mb-3"
                                    onSelect={handleTabSelect}
                                >
                                    <Tab 
                                        eventKey="Balance Enquiry" 
                                        title="Balance Enquiry"
                                    >
                                        <BlanceEnquiry 
                                            active={active} 
                                            apiAepsType={apiAepsType} 
                                        />
                                    </Tab>
                                    
                                    {!isInstantpayOnBoarded && (
                                        <Tab 
                                            eventKey="Cash Deposit" 
                                            title="Cash Deposit"
                                        >
                                            <CashDeposit 
                                                active={active} 
                                                apiAepsType={apiAepsType} 
                                                walletShowHeader={walletShowHeader} 
                                            />
                                        </Tab>
                                    )}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default CasDepositTab;