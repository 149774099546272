    import React from 'react';
import { FaArrowDownLong, FaArrowUpLong, FaEyeSlash, FaEye } from "react-icons/fa6";

const Dashboardcard = ( data ) => {
    console.log("data",data.dmtTotal)
  return (
   <>
    <div className="card-body">
            <div className="row">
            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="total-txn">
                                            <div>
                                                <span role="img" aria-label="insert-row-above" size={52} className="anticon anticon-insert-row-above hover:animate-spin border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4">
                                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="insert-row-above" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs></defs><path d="M878.7 336H145.3c-18.4 0-33.3 14.3-33.3 32v464c0 17.7 14.9 32 33.3 32h733.3c18.4 0 33.3-14.3 33.3-32V368c.1-17.7-14.8-32-33.2-32zM360 792H184V632h176v160zm0-224H184V408h176v160zm240 224H424V632h176v160zm0-224H424V408h176v160zm240 224H664V632h176v160zm0-224H664V408h176v160zm64-408H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8z" /></svg>
                                                </span>
                                            </div>
                                            <div className="rupees">
                                                <span>{data?.data?.dmtTotal ?? 0} ₹</span>
                                            </div>
                                            <div className="tName">
                                                <span>Total Txn</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="total-txn money_in">
                                            <div>
                                                <span role="img" aria-label="insert-row-above" size={52} className="anticon anticon-insert-row-above hover:animate-spin border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4">
                                                    <FaArrowDownLong />
                                                </span>
                                            </div>
                                            <div className="rupees">
                                                <span>{data?.data?.wallet?.creditTotal ?? 0} ₹</span>
                                            </div>
                                            <div className="tName">
                                                <span>Credit</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="total-txn Money-out mt-3">
                                            <div>
                                                <span role="img" aria-label="insert-row-above" size={52} className="anticon anticon-insert-row-above hover:animate-spin border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4">
                                                    <FaArrowUpLong />
                                                </span>
                                            </div>
                                            <div className="rupees">
                                                <span>{data?.data?.wallet?.debitTotal ?? 0} ₹</span>
                                            </div>
                                            <div className="tName">
                                                <span>Debit</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="total-txn dispute mt-3">
                                            <div>
                                                <span role="img" aria-label="insert-row-above" size={52} className="anticon anticon-insert-row-above hover:animate-spin border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4">
                                                    <FaArrowUpLong />
                                                </span>
                                            </div>
                                            <div className="rupees">
                                                {console.log(data?.disputeRequest ?? 0)}
                                                <span>{data?.data?.disputeRequest ?? 0}</span>
                                            </div>
                                            <div className="tName">
                                                <span>Dispute Request</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
        </div>
   </>
  )
}

export default Dashboardcard
