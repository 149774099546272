import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPhone, FaClock, FaEnvelope, FaCommentDots } from 'react-icons/fa'; // Using FontAwesome icons

import imageLogo from '../../asesets/logo/image 2.png';
import './helpline.css';

function MerchantLoginHeader({ loginForm, handleSubmitBack, currentPage }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const navigate = useNavigate();

    const logOut = () => {
        window.localStorage.clear();
        navigate('/login-area');
    };

    // Choose one of these helpline content options

    // Option 1: Animated sliding text
    const helplineContent1 = (
        <div className="helpline-animated">
            <span className="sliding-text">Need assistance? We're here to help!</span>
            <a href="tel:18008890178" className="helpline-link">
        <FaPhone size={20} className="me-2" />
        <span className="fw-bold">1800 889 0178</span>
            </a>
        </div>
    );

    // Option 2: Multiple contact options
    const helplineContent2 = (
        <div className="helpline-multi">
            <a href="tel:+1234567890" className="helpline-link me-3">
                <FaPhone size={20} className="me-1" />
                <span className="fw-bold">Call Us</span>
            </a>
            <a href="mailto:support@example.com" className="helpline-link me-3">
                <FaEnvelope size={20} className="me-1" />
                <span className="fw-bold">Email</span>
            </a>
            <a href="#" className="helpline-link">
                <FaCommentDots size={20} className="me-1" />
                <span className="fw-bold">Live Chat</span>
            </a>
        </div>
    );

    // Option 3: Tooltip with operating hours
    const helplineContent3 = (
        <div className="helpline-tooltip" 
             onMouseEnter={() => setShowTooltip(true)}
             onMouseLeave={() => setShowTooltip(false)}>
            <a href="tel:18008890178" className="helpline-link">
        <FaPhone size={20} className="me-2" />
        <span className="fw-bold"> 1800 889 0178</span>
            </a>
            {showTooltip && (
                <div className="tooltip-content">
                    <FaClock size={16} className="me-1" />
                    <span>Operating Hours: 24/7</span>
                </div>
            )}
        </div>
    );

    // Option 4: Accent color and hover effect
    const helplineContent4 = (
        <div className="helpline-accent">
            <a href="tel:18008890178" className="helpline-link">
                <div className="icon-circle">
                    <FaPhone size={20} />
                </div>
                <span className="fw-bold">Helpline:  1800 889 0178</span>
            </a>
        </div>
    );

    return (
        <header className="MerchantLoginHeader">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="image-log">
                            <img src={imageLogo} alt="Logo" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-6 d-flex justify-content-center">
                        {/* Choose one of the helplineContent options here */}
                        {helplineContent4}
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 mt-2 mt-md-0">
                        <div className='buttonArea d-flex justify-content-end'>
                            {currentPage === '/registrationComplete' ? (
                                <button className='btn login-area' onClick={logOut}>LOGOUT</button>
                            ) : (
                                <>
                                    <Link to="/login-area" className='btn login-area me-2'>LOGIN</Link>
                                    <Link to="/Signup" className='btn login-area'>SIGNUP</Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default MerchantLoginHeader;