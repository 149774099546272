import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const PageContainer = styled(Container)({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f0f0f0',
});

const ErrorCode = styled(Typography)({
  fontSize: '120px',
  color: '#3498db',
  position: 'relative',
  '& span': {
    color: '#2980b9',
  },
});

const ErrorMessage = styled(Typography)({
  fontSize: '24px',
  color: '#3498db',
  marginTop: '10px',
});

const ErrorDescription = styled(Typography)({
  fontSize: '16px',
  color: '#7f8c8d',
  marginBottom: '20px',
});

const StyledButton = styled(Button)({
  backgroundColor: '#34495e',
  '&:hover': {
    backgroundColor: '#2c3e50',
  },
});

const Figure = styled(Box)({
  position: 'absolute',
  fontSize: '24px',
  color: '#7f8c8d',
});

const NotFound = () => {
  return (
    <PageContainer fluid>
      <Row className="justify-content-center">
        <Col xs={12} className="text-center">
          <ErrorCode variant="h1">
            4<span>0</span>4
            <Figure sx={{ left: '20%', bottom: '40%' }}>
              Hi
              <span role="img" aria-label="waving hand">👋</span>
            </Figure>
            <Figure sx={{ right: '20%', top: '30%' }}>
              ?
              <span role="img" aria-label="thinking face">🤔</span>
            </Figure>
          </ErrorCode>
          <ErrorMessage variant="h2">
            Page not found
          </ErrorMessage>
          <ErrorDescription variant="body1">
            Sorry, we couldn't find the page you are looking for
          </ErrorDescription>
          <StyledButton variant="contained" href="/" color="primary">
            Login
          </StyledButton>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default NotFound;