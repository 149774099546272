import DasBoardCheck from "../../../components/admin/daseboardCheck/DasBoardChaeck"


function DaseboardCheckPage({walletData,services,position,isInstantpaypresnt}) {
    return (
        <>
            <DasBoardCheck walletData={walletData} services={services} position={position} isInstantpaypresnt={isInstantpaypresnt}/>
        </>
    )
}
export default DaseboardCheckPage