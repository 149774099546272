import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { createBank2RemiiterOtp , resendbank3otp } from "../../../../../api/login/Login";
import { toastErrorMessage } from "../../../../compeleteRegister/ToastShare";
const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  
  // Remove the +91 prefix if it exists
  let cleanedNumber = phoneNumber.startsWith('+91') ? phoneNumber.slice(3) : phoneNumber;
  
  // Remove any non-digit characters (including spaces)
  cleanedNumber = cleanedNumber.replace(/\D/g, '');
  
  return cleanedNumber;
};

function Otpcreatebank2({ show, onHide, initialCustomer, toastSuccessMessage ,SubmitCustomer , setBank2Kyc}) {
  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [isResending, setIsResending] = useState(false);
  const phoneNumber = localStorage.getItem('regisNumber');
  const formattedPhoneNumber = initialCustomer.mobile;
  console.log("initialCustomer" , )
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 4);
    setOtp(value);
    setError("");
  };

  const otpSubmit = async () => {
    if (otp.length !== 4) {
      setError("Please enter a 4-digit OTP");
      return;
    }
    setIsSubmitting(true);
    setError("");
    try {
      const response = await createBank2RemiiterOtp ({
        mobile: formattedPhoneNumber,
        api_id: initialCustomer.api_id,
        user_id: window.localStorage.getItem("userIdToken"),
        otp: otp,
      });
      if (response?.data?.statusCode === 200) {
        toastSuccessMessage(response?.data?.message);
        onHide();
        SubmitCustomer  && SubmitCustomer (true)
      } else if (response?.data?.statusCode == "002") {
        setBank2Kyc(true);
        toastErrorMessage(response?.data?.message || "Further action required for KYC");
      } else {
        toastErrorMessage(response?.message || "Verification failed")
        setError(response?.message || "Verification failed");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendOtp = async () => {
    setResendDisabled(true);
    setResendTimer(30);
    setIsResending(true);
    try {
      const response = await resendbank3otp({
       mobile: formattedPhoneNumber,
        user_id: window.localStorage.getItem("userIdToken"),
      });
      if (response?.data?.statusCode === 200) {
        toastSuccessMessage(response?.data?.message || "OTP resent successfully");
      } else {
        toastErrorMessage(response?.data?.message || "Failed to resend OTP");
      }
    } catch (error) {
      toastErrorMessage("An error occurred while resending OTP");
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="otp-customer"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">OTP Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="otpInput">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter 4-digit OTP"
              value={otp}
              onChange={handleChange}
              isInvalid={!!error}
              maxLength={4}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </Form.Group>
        </Form>
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      </Modal.Body>              
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={otpSubmit} disabled={isSubmitting || otp.length !== 4}>
          {isSubmitting ? <><Spinner animation="border" size="sm" /> Verifying...</> : "Verify OTP"}
        </Button>
        {/* <Button variant="info" onClick={resendOtp} disabled={resendDisabled}>
          {isResending ? <Spinner animation="border" size="sm" /> : null}
          {resendDisabled ? `Resend OTP (${resendTimer}s)` : "Resend OTP"}
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default Otpcreatebank2;